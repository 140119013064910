import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../../config/authAxios";
import apiRoutes from "../../apiConfigs";

export const getEventModuleSettingAPI = createAsyncThunk(
  "auth/event/getParticularEventModuleSetting",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.get(
        apiRoutes.publicapiAction(`events_setting`, 1), { params: data }
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
const intialState = { result: [], loading: false, error: null, message: "" };
const getDetailsEventModuleSettingSlice = createSlice({
  name: "get EventModuleSetting",
  initialState: { result: [], loading: false, error: null, message: "" },
  reducers: {
    resetGetEventModuleSetting: () => intialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getEventModuleSettingAPI.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getEventModuleSettingAPI.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(getEventModuleSettingAPI.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});
export const { resetGetEventModuleSetting } = getDetailsEventModuleSettingSlice.actions;
export const getDetailsEventModuleSettingReducer =
  getDetailsEventModuleSettingSlice.reducer;
