import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../../config/authAxios";
import apiRoutes from "../../apiConfigs";

export const getReportReasonAPI = createAsyncThunk(
  "auth/teacher-lounge/getPaticularReportReason",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.get(
        apiRoutes.publicapiAction(`report_reason`, 1)
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
const intialState = { result: [], loading: false, error: null, message: "" };
const getDetailsReportReasonSlice = createSlice({
  name: "get ReportReason",
  initialState: { result: [], loading: false, error: null, message: "" },
  reducers: {
    resetgetReportReason: () => intialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getReportReasonAPI.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getReportReasonAPI.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.data;
      state.message = action?.payload?.message;
    });
    builder.addCase(getReportReasonAPI.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});
export const { resetgetReportReason } = getDetailsReportReasonSlice.actions;
export const getDetailsReportReasonReducer =
  getDetailsReportReasonSlice.reducer;
