import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Button,
  Card,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { updatePublishUnpublishPostApi } from "../store/teacher-lounge/updatePublishUnpublish";

export default function PublishUnpublishModule({ open, setOpen, selectedRow, queryOptions, paginationModel, moduleType, moduleList }) {
  const dispatch = useDispatch();
  const handleClose = () => setOpen(false);
  const handlePublish = async (row) => {
    dispatch(updatePublishUnpublishPostApi({
      post_id: (moduleType==="flaggedPost")?row?.content_object?.id:row.id,
      post_approval:(moduleType==="flaggedPost")?(row?.content_object?.post_approval)?false : true:(row.post_approval) ? false : true
    })).then(() => {
      moduleList(queryOptions, paginationModel)
    })
    setOpen(false)
  }
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Card className="modal" sx={{ width: "30%" }}>
          <Stack alignItems="flex-end">
            <IconButton onClick={handleClose}>
              <HighlightOffIcon />
            </IconButton>
          </Stack>
          <Stack alignItems="center" my={2}>
            <Typography variant="h5" fontWeight={600}>
              Confirm {(moduleType==="flaggedPost")?(selectedRow?.content_object?.post_approval)?"Unpublish" : "Publish":selectedRow?.post_approval ? "Unpublish" : "Publish"} !!
            </Typography>
          </Stack>


          <Stack sx={{ mt: 2 }} justifyContent="center" direction="row">
            <Button variant="outlined" onClick={() => {
              handlePublish(selectedRow)
            }} sx={{ mr: 2 }}>
              Yes
            </Button>
            <Stack direction="row" spacing={1}>
              <Button variant="contained" onClick={() =>
                setOpen(false)
              }>
                No
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Modal>
    </>
  );
}

PublishUnpublishModule.propTypes = {
  queryOptions: PropTypes.any,
  paginationModel: PropTypes.any,
  open: PropTypes.any,
  setOpen: PropTypes.any,
  selectedRow: PropTypes.any,
  id: PropTypes.any,
};
