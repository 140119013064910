import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const RankAssignApi = createAsyncThunk(
  "auth/RankAssign",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.patch(apiRoutes.competitionAction(`update-rank`, 1), data,{params:data})
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const RankAssignSlice = createSlice({
  name: "Rank Assign",
  initialState: { result: [], loading: false, error: null, message: "" },
  extraReducers: (builder) => {
    builder.addCase(RankAssignApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(RankAssignApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(RankAssignApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});

export const RankAssignreducer = RankAssignSlice.reducer;
