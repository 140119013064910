import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const competitionUserEnteryDeleteApi = createAsyncThunk(
  "auth/competition/user/delete",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.delete(apiRoutes.reawardAction(`${id}`, 1));
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const competitionUserEnteryDeleteSlice = createSlice({
  name: "competition delete",
  initialState: { result: [], loading: false, error: null, message: "" },
  extraReducers: (builder) => {
    builder.addCase(competitionUserEnteryDeleteApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(competitionUserEnteryDeleteApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(competitionUserEnteryDeleteApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});

export const competitionUserEnteryDeleteReducer = competitionUserEnteryDeleteSlice.reducer;
