import { yupResolver } from "@hookform/resolvers/yup";
import {
  Badge,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { addReportreasonApi } from "../../store/teacher-lounge/report-reason/addreason";
import { EditReportReasonApi } from "../../store/teacher-lounge/report-reason/editreason";
const schema = yup
  .object({
    reason: yup.string().required("Please add reason").trim(),
  })
  .required();

export default function ReportReasonEdit() {
  const settingDetails = useSelector((state) => state?.settingDetails);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const params = useParams();
  const navigate = useNavigate();
  const classAdd = useSelector((state) => state?.classAdd);
  const classEdit = useSelector((state) => state?.classEdit);
  const historyLocation = useLocation();
  const { reportReasonData } = historyLocation.state || {};
  const handleUpdateForm = async (value) => {
    var newObj;

    if (params.id) {
      newObj = { ...value, reason_id: params.id };
      await dispatch(EditReportReasonApi(newObj));
      if (!classEdit?.loading) {
        navigate("/report-reason");
      }
    } else {
      await dispatch(addReportreasonApi(value));
      if (!classAdd?.loading) {
        navigate("/report-reason");
      }
    }
  };

  useEffect(() => {
    let defalutValues = {};
    if (params.id) {
      defalutValues["reason"] = reportReasonData?.reason;
    }
    reset(defalutValues);
  }, [reportReasonData]);

  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Typography>Report Reason Details</Typography>
                <Stack spacing={2} my={2}>
                  <Divider />

                  <Stack direction="row" spacing={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-category" shrink>
                        Reason
                      </InputLabel>
                      <OutlinedInput
                        {...register("reason")}
                        multiline
                        label="reason"
                        notched
                        id="outlined-adornment-category"
                        error={errors?.reason?.message}
                      />
                      <FormHelperText error>
                        {errors?.reason?.message}
                      </FormHelperText>
                    </FormControl>

                  </Stack>
                </Stack>
              </Card>
              <Button
                type="button"
                sx={{ float: "right", mt: 2, ml: 2 }}
                variant="contained"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2 }}
                variant="contained"
              >
                {params.id ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Body>
    </>
  );
}
