import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../../config/authAxios";
import apiRoutes from "../../apiConfigs";

export const reportReasonDeleteApi = createAsyncThunk(
  "auth/teacher-lounge/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.delete(apiRoutes.publicapiAction(`report_reason`, 1), { data });
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const reportReasonDeleteSlice = createSlice({
  name: "reportReason delete",
  initialState: { result: [], loading: false, error: null, message: "" },
  extraReducers: (builder) => {
    builder.addCase(reportReasonDeleteApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(reportReasonDeleteApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(reportReasonDeleteApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});

export const reportReasonDeleteReducer = reportReasonDeleteSlice.reducer;
