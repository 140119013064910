import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes, { adminweb } from "../apiConfigs"

export const updatePublishUnpublishPostApi = createAsyncThunk(
    'auth/publish/post',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post(`${adminweb}/approve_post/`, data)
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const updatePublishUnpublishSlice = createSlice({
    name: 'admin publish post',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(updatePublishUnpublishPostApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(updatePublishUnpublishPostApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(updatePublishUnpublishPostApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const updatePublishUnpublishReducer = updatePublishUnpublishSlice.reducer