import { useEffect, useState, useCallback, useContext } from "react";
import {
    Box,
    Stack,
    Typography,
} from "@mui/material";
import { getGridStringOperators } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { contentReportTableApi } from "../../store/analytics/contentReport";
import { ThemeContext } from '../../context/ThemeContext';
import NodataImg from "../../assets/images/noData.png";
import CustomGridToolbar from "../../components/CustomGridToolbar";
import { contentReport } from "../../store/apiConfigs";
import BackdropUI from "../../ui/Backdrop";
import DataGridComponent from "../../components/DataGridComponent";

const columns = [

    {
        field: "id",
        headerName: "Sno.",
        width: 150,
        filterable: false,
        sortable: false,
    },
    {
        field: "title",
        headerName: "Title",
        width: 450,
        filterable: true,
        filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === 'equals' | operator.value === 'contains',
        ),
        sortable: false,
    },
    {
        field: "subjects",
        headerName: "Subjects",
        width: 200,
        renderCell: (data) => (<>
            <Typography sx={{ overflowWrap: "anywhere" }}>{Array.isArray(data?.row?.subjects) && data?.row?.subjects.toString()}</Typography>
        </>),
        filterable: false,
        sortable: false,
    },
    {
        field: "classes",
        headerName: "Classes",
        width: 200,
        renderCell: (data) => (<>
            <Typography sx={{ overflowWrap: "anywhere" }}>{Array.isArray(data?.row?.classes) && data?.row?.classes.toString()}</Typography>
        </>),
        filterable: false,
        sortable: false,
    },
    {
        field: "medium",
        headerName: "Medium",
        width: 200,
        filterable: false,
        sortable: false,
    },
    {
        field: "category",
        headerName: "Category",
        width: 200,
        renderCell: (data) => (<>
            <Typography sx={{ overflowWrap: "anywhere" }}>{data?.row?.category}</Typography>
        </>),
        filterable: true,
        filterOperators: getGridStringOperators().filter(
            (operator) => operator.value === 'equals' | operator.value === 'contains',
        ),
        sortable: false,
    },
    {
        field: "bookmark_count",
        headerName: "Bookmark count",
        width: 200,
        filterable: false,
        sortable: false,
    },
    {
        field: "like_count",
        headerName: "Like count",
        width: 200,
        filterable: false,
        sortable: false,
    },
    {
        field: "download_count",
        headerName: "Download count",
        width: 200,
        filterable: false,
        sortable: false,
    },
    {
        field: "total_time_spent_by_users",
        headerName: "Total time spent by user",
        width: 200,
        filterable: false,
        sortable: false,
    },
    {
        field: "avg_time_spent_by_users",
        headerName: "Average time spent by user",
        width: 200,
        filterable: false,
        sortable: false,
    },
];

export default function ContentConsumptionNonGraded({ type, startDate, monthDaily, endDate, module, region, state }) {
    const [numofresources, setNumofresources] = useState({ l1: true, l2: true, l3: true, l4: true });
    const toggleNumofresources = useCallback((key) => {
        setNumofresources(prevState => ({ ...prevState, [key]: !prevState[key] }));
    }, []);
    const contentReportTable = useSelector((state) => state?.contentReportTable);
    const { result: tableResult, loading: tableLoading, total } = contentReportTable;
    const dispatch = useDispatch();
    const [isDownload, setIsDownload] = useState(false)

    const [queryOptions, setQueryOptions] = useState({});
    const { paginationModel, setPaginationModel } = useContext(ThemeContext)

    const onFilterChange = useCallback((filterModel) => {
        setQueryOptions({ filterModel: { ...filterModel } });
    }, []);

    const getRowSpacing = useCallback((params) => {
        return {
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
        };
    }, []);
    useEffect(() => {
        const filterModel = queryOptions?.filterModel;
        const items = filterModel?.items;
        const firstItem = items && items.length > 0 ? items[0] : {};
        const quickFilterValues = filterModel?.quickFilterValues;

        const query = {
            page: paginationModel?.page + 1,
            page_size: paginationModel?.pageSize,
            field: firstItem.field || '',
            module_name: module,
            city: region,
            state: state,
            startDate: startDate,
            endDate: endDate,
            operator: firstItem.operator || (quickFilterValues && "contains") || '',
            value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || ''
        };
        dispatch(contentReportTableApi(query));
    }, [dispatch, queryOptions, paginationModel, module, region, state, startDate, endDate]);

    return (<>
        <Stack direction="row" spacing={1} sx={{ my: 2, padding: "10px" }} alignItems="center" justifyContent={"space-between"}>
            <Typography variant="h3">Content Consumption</Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ my: 2, padding: "10px" }} alignItems="center">
            <DataGridComponent
                loading={tableLoading}
                rowCount={total}
                pageSize={paginationModel?.pageSize}
                onPageSizeChange={(newPageSize) => {
                    setPaginationModel({
                        ...paginationModel,
                        pageSize: newPageSize,
                    })
                }}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                sx={{
                    height: 500
                }}
                getRowId={(row) => row.id}
                rows={tableResult}
                getRowSpacing={getRowSpacing}
                columns={columns}
                onFilterModelChange={onFilterChange}
                slots={{
                    noRowsOverlay: () => (
                        <Box className="noData">
                            <img src={NodataImg} alt="no data found" />
                        </Box>
                    ),
                    toolbar: (props) =>
                    (CustomGridToolbar({
                        ...props, moduleType: "contentConsumption", queryOptions: queryOptions, paginationModel: paginationModel, isDownload: isDownload, setIsDownload: setIsDownload, apiUrl: contentReport,
                        module: module,
                        region: region,
                        state: state,
                        startDate: startDate,
                        endDate: endDate
                    })),
                }}
                slotProps={{
                    loadingOverlay: {
                        variant: 'linear-progress',
                        noRowsVariant: 'linear-progress',
                    },
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
            />
        </Stack>
        <BackdropUI open={isDownload} />
    </>)
}