import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { HiTemplate } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import NodataImg from "../../../assets/images/noData.png";
import Appbar from "../../../components/Appbar";
import Body from "../../../components/Body";
import Drawer from "../../../components/Drawer";
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate,useParams } from "react-router-dom";
import { getEventModuleSettingAPI, resetGetEventModuleSetting } from "../../../store/event-module/settings/geteventlist";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DataGridComponent from "../../../components/DataGridComponent";
import CustomGridToolbar from "../../../components/CustomGridToolbarWithoutDownload";
import DeleteEventModule from "./DeleteEventModuleSetting";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

export default function EventModule() {
  const [tableData, setTableData] = useState([])
  const settingList = useSelector((state) => state?.getDetailsEventModuleSetting);
  const { result, loading } = settingList;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchObj = useParams();
  const [deleteId, setDeleteId] = useState(null);
  const [selectedRow, setSelectedRow] = useState([]);
  const [notSelectedProductModel, setNotSelectedProductModel] = useState(false);
  const handleNavigation = async (id, eventModuleData) => {
    navigate(`edit-event-module/${id}`, { state: { eventModuleData } });
  };
  const columns = [
    {
      field: "id",
      headerName: "Event Setting Id",
      filterable: false,
      sortable: false,
      width: 150,
    },
    {
      field: "event",
      headerName: "Event Id",
      filterable: false,
      sortable: false,
      width: 150,
    },
    {
      field: "background_image",
      headerName: "Background Image",
      width: 250,
      filterable: false,
      sortable: false,
      renderCell: (params) => (<img width={200} height={100} src={params.row.background_image} alt='background image' />)
    },
    {
      field: "event_guidelines",
      headerName: "Event guidelines",
      width: 250,
      filterable: false,
      sortable: false,
      renderCell: (params) => (<img width={200} height={100} src={params.row.event_guidelines} alt='event guidelines' />)
    },
    {
      field: "update_setting",
      headerName: "Update",
      width: 150,
      filterable: false,
      sortable: false,
      disableExport: true,
      renderCell: (row) => (
        <>
          <Button
            color="info"
            endIcon={<EditIcon />}
            label="Available" variant="outlined"
            onClick={() => handleNavigation(row.id, row.row)}
          >
            Edit
          </Button>
        </>
      ),
    },
    {
      field: "delete_event_module",
      headerName: "Delete Event Setting",
      filterable: false,
      sortable: false,
      width: 150,
      disableExport: true,
      renderCell: (row) => (
        <>
          <Button
            endIcon={<DeleteOutlineIcon />}
            color="error"
            variant="outlined"
            style={{ marginLeft: "5px" }}
            onClick={() => {
              setNotSelectedProductModel(true);
              setDeleteId(row.id);
            }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];
  useEffect(() => {
    dispatch(getEventModuleSettingAPI(searchObj));
  }, [dispatch]);

  const addToCartHandler = async () => {
    navigate("add-event-module");
  };
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>


        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <HiTemplate size={30} />
          <Typography>Event setting management</Typography>
        </Stack>
        <Stack sx={{ my: 2 }} justifyContent="space-between" direction="row">
          <Stack direction="row" spacing={1}>
            <Button
              onClick={addToCartHandler}
              variant="contained"
            >
              add event settings
            </Button>
          </Stack>
        </Stack>
        <Paper elevation={0}>
          <DataGridComponent
            sx={{
              height: 500
            }}
            getRowId={(row) => row.id}
            rows={result}
            columns={columns}
            loading={loading}
            slots={{
              noRowsOverlay: () => (
                <Box className="noData">
                  <img src={NodataImg} alt="no data found" />
                </Box>
              ),
              toolbar: CustomGridToolbar,
            }}
            slotProps={{
              loadingOverlay: {
                variant: 'linear-progress',
                noRowsVariant: 'linear-progress',
              },
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </Paper>

      </Body>
      <DeleteEventModule
        open={notSelectedProductModel}
        setOpen={setNotSelectedProductModel}
        setSelectedRow={setSelectedRow}
        id={deleteId}
      />
    </>
  );
}
