import { yupResolver } from "@hookform/resolvers/yup";
import {
  Badge,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import Appbar from "../../../components/Appbar";
import Body from "../../../components/Body";
import Drawer from "../../../components/Drawer";
import { addEventModuleSettingsApi } from "../../../store/event-module/settings/addevent";
import { EditEventModuleSettingApi } from "../../../store/event-module/settings/editevent";
import { uploadImageApi } from "../../../store/upload/uploadImage";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ImageUploading from "react-images-uploading";
import dayjs from "dayjs";
import { toast } from "react-hot-toast";
import BackdropUI from "../../../ui/Backdrop";

const schema = yup
  .object({
    
  })
  .required();

export default function EventModuleEdit() {
  const settingDetails = useSelector((state) => state?.settingDetails);
  const [fileUploadLoader, setFileUploadLoader] = useState(false);
  const [fileUploadLoader2, setFileUploadLoader2] = useState(false);
  const uploadImage = useSelector((state) => state?.uploadImage);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const params = useParams();
  const navigate = useNavigate();
  const classAdd = useSelector((state) => state?.addEventModule);
  const classEdit = useSelector((state) => state?.EditEventModule);
  const historyLocation = useLocation();
  const [imageLink, setImageLink] = useState(null);
  const [imageLink2, setImageLink2] = useState(null);
  const [images, setImages] = React.useState([]);
  const [images2, setImages2] = React.useState([]);

  const [error, setError] = useState(null);
  const [dateTime, setDateTime] = useState();
  const [endTime, setEnd] = useState();
  const { eventModuleData } = historyLocation.state || {};
  const handleUpdateForm = async (value) => {
    var newObj;
    newObj = { ...value, ...params };
    if (imageLink) {
      newObj = { ...newObj, event_guidelines_url: imageLink };
    } else if (
      images.length > 0 &&
      !uploadImage?.loading &&
      uploadImage.result
    ) {
      newObj = { ...newObj, event_guidelines_url: uploadImage.result };
    } else {
      toast.error("Please Upload An Eventguideline document", {
        id: "toast",
        duration: 1000,
        position: "top-center",
      });
      return;
    }
    if (imageLink2) {
      newObj = { ...newObj, background_image_url: imageLink2 };
    } else if (
      images.length > 0 &&
      !uploadImage?.loading &&
      uploadImage.result
    ) {
      newObj = { ...newObj, background_image_url: uploadImage.result };
    } else {
      toast.error("Please Upload An Background Image", {
        id: "toast",
        duration: 1000,
        position: "top-center",
      });
      return;
    }
    if (params.setting_id) {
      await dispatch(EditEventModuleSettingApi(newObj));
      if (!classEdit?.loading) {
        navigate("/events");
      }
    } else {
      await dispatch(addEventModuleSettingsApi(newObj));
      if (!classAdd?.loading) {
        navigate("/events");
      }
    }
  };
  const onUploadImage = async (imageList, addUpdateIndex) => {
    try {
      let formData = new FormData();
      formData.append("file", imageList[0]?.file);
      if (imageList.length > 0) {
        setFileUploadLoader(true);
        dispatch(uploadImageApi(formData)).then((action) => {
          if (!action.type.includes("rejected")) {
            setImages(imageList);
          }
        }).finally(() => {
          setFileUploadLoader(false);
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
      setImages(imageList);
      // setFileUploadLoader(false)
    }

  };
  const onUploadImage2 = async (imageList, addUpdateIndex) => {
    try {
      let formData = new FormData();
      formData.append("file", imageList[0]?.file);
      if (imageList.length > 0) {
        setFileUploadLoader2(true);
        dispatch(uploadImageApi(formData)).then((action) => {
          if (!action.type.includes("rejected")) {
            setImages2(imageList);
          }
        }).finally(() => {
          setFileUploadLoader2(false);
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
      setImages2(imageList);
      // setFileUploadLoader(false)
    }

  };
  useEffect(() => {
    let defalutValues = {};
    if (params.setting_id) {
      setImageLink(eventModuleData?.event_guidelines);
      setImageLink2(eventModuleData?.background_image);
    }
    reset(defalutValues);
  }, [eventModuleData]);

  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Typography>Event Setting Module Details</Typography>
                <Stack spacing={2} my={2}>
                  <Divider />

                  <InputLabel>Event Guidlines</InputLabel>
                  {imageLink ? (
                    <div className="image-item">
                      <img src={`${imageLink}?t=${Date.now()}`} alt="" width="320" height="180" />
                      <div className="image-item__btn-wrapper">
                        <Button
                          onClick={() => setImageLink(null)}
                          variant="contained"
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <ImageUploading
                      acceptType={['png', 'jpeg', 'jpg']}
                      value={images}
                      onChange={onUploadImage}
                      maxNumber={1}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                        errors,
                      }) => (
                        <>
                          <div
                            className="upload__image-wrapper"
                            style={{ marginTop: "2%", marginBottom: "2%" }}
                          >
                            {imageList.length > 0 ? null : (
                              <Button
                                type="button"
                                variant="contained"
                                style={isDragging ? { color: "red" } : undefined}
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                Click or Drop here
                              </Button>
                            )}
                            {errors && (
                              <div>
                                {errors.maxNumber && (
                                  <span>
                                    Number of selected images exceed maxNumber
                                  </span>
                                )}
                                {errors.acceptType && (
                                  <span>
                                    Your selected file type is not allow
                                  </span>
                                )}
                                {errors.maxFileSize && (
                                  <span>
                                    Selected file size exceed maxFileSize
                                  </span>
                                )}
                                {errors.resolution && (
                                  <span>
                                    Selected file is not match your desired
                                    resolution i.e (328*150)
                                  </span>
                                )}
                              </div>
                            )}
                            {imageList.map((image, index) => (
                              <div key={index} className="image-item">
                                <img
                                  src={image["data_url"]}
                                  alt=""
                                  width="320" height="180"
                                />
                                <div className="image-item__btn-wrapper">
                                  <Button
                                    onClick={() => onImageUpdate(index)}
                                    variant="contained"
                                    sx={{ mr: 2 }}
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    onClick={() => onImageRemove(index)}
                                    variant="contained"
                                  >
                                    Remove
                                  </Button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </ImageUploading>
                  )}
                  <InputLabel>Background Image</InputLabel>
                  {imageLink2 ? (
                    <div className="image-item">
                      <img src={`${imageLink2}?t=${Date.now()}`} alt="" width="320" height="180" />
                      <div className="image-item__btn-wrapper">
                        <Button
                          onClick={() => setImageLink2(null)}
                          variant="contained"
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <ImageUploading
                      acceptType={['png', 'jpeg', 'jpg']}
                      value={images2}
                      onChange={onUploadImage2}
                      maxNumber={1}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                        errors,
                      }) => (
                        <>
                          <div
                            className="upload__image-wrapper"
                            style={{ marginTop: "2%", marginBottom: "2%" }}
                          >
                            {imageList.length > 0 ? null : (
                              <Button
                                type="button"
                                variant="contained"
                                style={isDragging ? { color: "red" } : undefined}
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                Click or Drop here
                              </Button>
                            )}
                            {errors && (
                              <div>
                                {errors.maxNumber && (
                                  <span>
                                    Number of selected images exceed maxNumber
                                  </span>
                                )}
                                {errors.acceptType && (
                                  <span>
                                    Your selected file type is not allow
                                  </span>
                                )}
                                {errors.maxFileSize && (
                                  <span>
                                    Selected file size exceed maxFileSize
                                  </span>
                                )}
                                {errors.resolution && (
                                  <span>
                                    Selected file is not match your desired
                                    resolution i.e (328*150)
                                  </span>
                                )}
                              </div>
                            )}
                            {imageList.map((image, index) => (
                              <div key={index} className="image-item">
                                <img
                                  src={image["data_url"]}
                                  alt=""
                                  width="320" height="180"
                                />
                                <div className="image-item__btn-wrapper">
                                  <Button
                                    onClick={() => onImageUpdate(index)}
                                    variant="contained"
                                    sx={{ mr: 2 }}
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    onClick={() => onImageRemove(index)}
                                    variant="contained"
                                  >
                                    Remove
                                  </Button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </ImageUploading>
                  )}
                </Stack>
              </Card>
              <Button
                type="button"
                sx={{ float: "right", mt: 2, ml: 2 }}
                variant="contained"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2 }}
                variant="contained"
              >
                {params.id ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Body>
      <BackdropUI open={fileUploadLoader || fileUploadLoader2} />
    </>
  );
}
