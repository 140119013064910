import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const competitionUserRankDeleteApi = createAsyncThunk(
  "auth/competition/user/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.patch(apiRoutes.competitionAction(`remove-rank`, 0),data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const competitionUserRankDeleteSlice = createSlice({
  name: "competition delete",
  initialState: { result: [], loading: false, error: null, message: "" },
  extraReducers: (builder) => {
    builder.addCase(competitionUserRankDeleteApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(competitionUserRankDeleteApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(competitionUserRankDeleteApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});

export const competitionUserRankDeleteReducer = competitionUserRankDeleteSlice.reducer;
