import { yupResolver } from "@hookform/resolvers/yup";
import {
  Badge,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Box,
  Chip
} from "@mui/material";
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useParams, useNavigate } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import ImageUploading from "react-images-uploading";
import DefaultProfileImg from "../../assets/images/defaultProfileImg.jpg";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Drawer from "../../components/Drawer";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Style from "../../styles/profile.module.scss";
import { TimeField } from "@mui/x-date-pickers";
import { toast } from "react-hot-toast";
import { uploadImageApi } from "../../store/upload/uploadImage";
import { sundayPollAddApi } from "../../store/sundayPolls/addSundayPoll";
import { sundayPollDetailsApi } from "../../store/sundayPolls/sundayPollDetail";
import { sundayPollEditApi } from "../../store/sundayPolls/editSundayPoll";
import { classGlobalApi } from "../../store/class/classListGlobal";
import { subjectGlobalApi } from "../../store/subject/subjectListGlobal";
import { useTheme } from '@mui/material/styles';
dayjs.extend(utc);
dayjs.extend(timezone);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const schema = yup
  .object({
    // title: yup.string().required("Please Provide instructions").trim(),
    poll_instruction: yup.string().required("Please Provide instructions").trim(),
    poll_question: yup.string().required("Please Provide poll question").trim(),
    poll_points: yup
      .number()
      .required("Please Provide Number Of Stars ")
      .min(1, "Minimum stars should be 1")
      .max(1000, "Max Stars Can Be 1000")
      .typeError("Please provide a number"),
    // date: yup.string().required("Please Provide Date "),
    poll_option_1: yup.string().required("Please Provide Option 1").trim(),
    poll_option_2: yup.string().required("Please Provide Option 2").trim(),
    poll_option_3: yup.string().required("Please Provide Option 3").trim(),
    poll_option_4: yup.string().required("Please Provide Option 4").trim(),
    language: yup.string().required("Please Select Language "),
    // password: yup.string(),
  })
  .required();

export default function EditPoll() {
  const theme = useTheme();
  const { result: classList, loading: classLoading } = useSelector((state) => state?.classGlobal);
  const { result: subjectList, loading: subjectLoading } = useSelector((state) => state?.subjectGlobal);
  const sundayPollEdit = useSelector((state) => state?.sundayPollEdit);
  const sundayPollAdd = useSelector((state) => state?.sundayPollAdd);
  const sundayPollDetail = useSelector((state) => state?.sundayPollDetail);
  const uploadImage = useSelector((state) => state?.uploadImage);
  const [language, setLanguage] = useState();
  const { result, loiding } = sundayPollDetail;
  const {
    result: {
      title,
      poll_instruction,
      poll_publish_datatime,
      poll_date,
      poll_question,
      poll_points,
      poll_pic,
      poll_option_1,
      poll_option_2,
      poll_option_3,
      poll_option_4,
    },
  } = sundayPollDetail;

  const shouldDisableDate = (day) => {
    return day.$d.getDay() !== 0; // Returns true for all days except Sunday (0).
  };

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    control
  } = useForm({ resolver: yupResolver(schema), defaultValues: {} });


  const [images, setImages] = React.useState([]);
  const [dateTime, setDateTime] = useState();
  const [imageLink, setImageLink] = useState();
  const ref = useRef(null);

  const onUploadImage = async (imageList, addUpdateIndex) => {
    if (imageList.length > 0) {
      let formData = new FormData();
      // console.log(imageList, imageList);
      formData.append("file", imageList[0]?.file);
      await dispatch(uploadImageApi(formData));
    }
    setImages(imageList);
  };
  const params = useParams();
  const navigate = useNavigate();
  const handleUpdateForm = async (data) => {

    var newObj;

    if (imageLink) {
      newObj = { ...data, poll_pic: imageLink };
    } else if (images.length > 0 && !uploadImage?.loading && uploadImage.result) {
      newObj = { ...data, poll_pic: uploadImage.result };

    } else {
      toast.error("Please Upload An Image", {
        id: "toast",
        duration: 1000,
        position: "top-center",
      });
      return;
    }
    newObj = { ...newObj, poll_publish_datatime: dateTime };

    if (params.id) {
      newObj = { ...newObj, id: params.id };
      const res = await dispatch(sundayPollEditApi(newObj));
      if (!sundayPollEdit?.loading) {
        if (res.payload.error === false) {
          navigate("/sunday-polls");
        }
        //console.log("edit");
      }
    } else {
      const res = await dispatch(sundayPollAddApi(newObj));
      if (!sundayPollAdd?.loading) {
        if (res.payload.error === false) {
          navigate("/sunday-polls");
        }
      }
    }
  };
  useEffect(() => {
    dispatch(classGlobalApi());
    dispatch(subjectGlobalApi());
    if (params.id && result) {
      dispatch(sundayPollDetailsApi(params.id));
    }
  }, [dispatch]);

  useEffect(() => {
    setDateTime(result.poll_publish_datatime);
    setLanguage(result?.language)
    setImageLink(
      result?.poll_pic
    );
  }, [sundayPollDetail]);

  useEffect(() => {
    let defalutValues = {};
    defalutValues["poll_instruction"] = result?.poll_instruction;
    defalutValues["poll_publish_datatime"] = result?.poll_publish_datatime;
    defalutValues["poll_question"] = result?.poll_question;
    defalutValues["poll_points"] = result?.poll_points;
    defalutValues["poll_pic"] = result?.poll_pic;
    defalutValues["poll_option_1"] = result?.poll_option_1;
    defalutValues["poll_option_2"] = result?.poll_option_2;
    defalutValues["poll_option_3"] = result?.poll_option_3;
    defalutValues["poll_option_4"] = result?.poll_option_4;
    defalutValues["title"] = result?.title;
    defalutValues["language"] = result?.language;
    defalutValues["subjects"] = result?.subjects?.map(o => o?.id);
    defalutValues["classes"] = result?.classes?.map(o => o?.id);
    reset(defalutValues);
  }, [result]);
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Stack spacing={2} my={2}>
                  {/* {accountToggle == "accountToggle" && */}
                  <>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                      
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            {...register("poll_publish_datatime")}
                            label="Date & Time"
                            name="date"
                            disabled={params.id && new Date().toJSON() > poll_publish_datatime}
                            error={errors?.date?.message}
                            value={dateTime ? dayjs(dateTime).tz('Asia/Kolkata') : null}
                            onChange={(newValue) => {
                              setDateTime(dayjs(newValue).toJSON());
                              console.log("sajhdaskjdasd", dayjs(newValue).utc().toJSON())
                            }}
                            shouldDisableDate={shouldDisableDate}
                          />
                        </LocalizationProvider>
                        <FormHelperText error>
                          {errors?.date?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Language
                        </InputLabel>
                        <Select
                          {...register("language")}
                          label="Language"
                          notched
                          id="outlined-adornment-category"
                          error={!!errors?.language?.message}
                          value={language ? language : null}
                          onChange={(e) => setLanguage(e.target.value)}
                        >
                          <MenuItem value={"hindi"}>Hindi</MenuItem>
                          <MenuItem value={"english"}>English</MenuItem>
                          <MenuItem value={"both"}>Both</MenuItem>
                        </Select>

                        <FormHelperText error>
                          {errors?.language?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Subject
                        </InputLabel>
                        <Controller
                          name="subjects"
                          control={control}
                          defaultValue={[]}
                          render={({ field }) => (
                            <Select
                              {...field}
                              multiple
                              value={watch('subjects', [])}
                              onChange={(e) => {
                                const {
                                  target: { value },
                                } = e;
                                setValue('subjects', typeof value === 'string' ? value.split(',') : value);
                                field.onChange(e);
                              }}
                              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                              renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={subjectList.find(obj => obj.id === value)?.title || ""} />
                                  ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {subjectList.map((obj) => (
                                <MenuItem
                                  key={obj.title}
                                  value={obj.id}
                                  style={getStyles(obj.title, watch('subjects', []), theme)}
                                >
                                  {obj.title}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        {errors.subjects && <span>{errors.subjects.message}</span>}
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email" shrink>
                          Class
                        </InputLabel>
                        <Controller
                          name="classes"
                          control={control}
                          defaultValue={[]}
                          render={({ field }) => (
                            <Select
                              {...field}
                              multiple
                              value={watch('classes', [])}
                              onChange={(e) => {
                                const {
                                  target: { value },
                                } = e;
                                setValue('classes', typeof value === 'string' ? value.split(',') : value);
                                field.onChange(e);
                              }}
                              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                              renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={classList.find(obj => obj.id === value)?.title || ""} />
                                  ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {classList.map((obj) => (
                                <MenuItem
                                  key={obj.title}
                                  value={obj.id}
                                  style={getStyles(obj.title, watch('classes', []), theme)}
                                >
                                  {obj.title}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        {errors.classes && <span>{errors.classes.message}</span>}
                        <FormHelperText error>
                          {errors?.classes?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Instructions*</InputLabel>
                        <OutlinedInput
                          {...register("poll_instruction")}
                          label="Description"
                          notched
                          multiline
                          error={errors?.poll_instruction?.message}
                          defaultValue={poll_instruction}
                        />
                        <FormHelperText error>
                          {errors?.poll_instruction?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Allocated Stars*</InputLabel>
                        <OutlinedInput
                          {...register("poll_points")}
                          label="Allocated Stars*"
                          notched
                          error={errors?.poll_points?.message}
                          defaultValue={poll_points}
                        />
                        <FormHelperText error>
                          {errors?.poll_points?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      {params.id && <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Title</InputLabel>
                        <OutlinedInput
                          {...register("title")}
                          label="Title*"
                          notched
                          disabled
                          multiline
                          error={errors?.title?.message}
                          defaultValue={title}
                        />
                        {/* <FormHelperText error>
                          {errors?.title?.message}
                        </FormHelperText> */}
                      </FormControl>}
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Question*</InputLabel>
                        <OutlinedInput
                          {...register("poll_question")}
                          label="Question*"
                          notched
                          disabled={params.id && new Date().toJSON() > poll_publish_datatime}
                          multiline
                          error={errors?.poll_question?.message}
                          defaultValue={poll_question}
                        />
                        <FormHelperText error>
                          {errors?.poll_question?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Option 1*</InputLabel>
                        <OutlinedInput
                          {...register("poll_option_1")}
                          label="Option 1*"
                          notched
                          disabled={params.id && new Date().toJSON() > poll_publish_datatime}
                          id="outlined-adornment-email"
                          error={errors?.poll_option_1?.message}
                          defaultValue={poll_option_1}
                        />
                        <FormHelperText error>
                          {errors?.poll_option_1?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Option 2*</InputLabel>
                        <OutlinedInput
                          {...register("poll_option_2")}
                          label="Option 2*"
                          notched
                          disabled={params.id && new Date().toJSON() > poll_publish_datatime}
                          id="outlined-adornment-email"
                          error={errors?.poll_option_2?.message}
                          defaultValue={poll_option_2}
                        />
                        <FormHelperText error>
                          {errors?.poll_option_2?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Option 3*</InputLabel>
                        <OutlinedInput
                          {...register("poll_option_3")}
                          label="Option 3*"
                          notched
                          disabled={params.id && new Date().toJSON() > poll_publish_datatime}
                          id="outlined-adornment-email"
                          error={errors?.poll_option_3?.message}
                          defaultValue={poll_option_3}
                        />
                        <FormHelperText error>
                          {errors?.poll_option_3?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Option 4*</InputLabel>
                        <OutlinedInput
                          {...register("poll_option_4")}
                          label="Option 4*"
                          notched
                          disabled={params.id && new Date().toJSON() > poll_publish_datatime}
                          id="outlined-adornment-email"
                          error={errors?.poll_option_4?.message}
                          defaultValue={poll_option_4}
                        />
                        <FormHelperText error>
                          {errors?.poll_option_4?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                  </>
                  {/* } */}
                </Stack>
                {/* <Stack direction="row" spacing={2}> */}
                <InputLabel>Poll Image*</InputLabel>
                {imageLink ? (
                  <div className="image-item">
                    <img src={imageLink} alt="" width="330" height="150" />
                    <div className="image-item__btn-wrapper">
                      <Button
                        onClick={() => setImageLink(null)}
                        variant="contained"
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                ) : (
                  <ImageUploading
                    // multiple
                    value={images}
                    onChange={onUploadImage}
                    maxNumber={1}
                    resolutionType="ratio"
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                      errors,
                    }) => (
                      <>
                        <div className="upload__image-wrapper" style={{ marginTop: "2%", marginBottom: "2%" }}>
                          {imageList.length > 0 ? null : (
                            <Button
                              type="button"
                              variant="contained"
                              style={isDragging ? { color: "red" } : undefined}
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              Click or Drop here
                            </Button>
                          )}

                          {errors && (
                            <div>
                              {errors.maxNumber && (
                                <span>
                                  Number of selected images exceed maxNumber
                                </span>
                              )}
                              {errors.acceptType && (
                                <span>
                                  Your selected file type is not allow
                                </span>
                              )}
                              {errors.maxFileSize && (
                                <span>
                                  Selected file size exceed maxFileSize
                                </span>
                              )}
                              {errors.resolution && (
                                <span>
                                  Selected file is not match your desired
                                  resolution
                                </span>
                              )}
                            </div>
                          )}
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img
                                src={image["data_url"]}
                                alt=""
                                width="330"
                                height="150"
                              />
                              <div className="image-item__btn-wrapper">
                                <Button
                                  onClick={() => onImageUpdate(index)}
                                  variant="contained"
                                  sx={{ mr: 2 }}
                                >
                                  Update
                                </Button>
                                <Button
                                  onClick={() => onImageRemove(index)}
                                  variant="contained"
                                >
                                  Remove
                                </Button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </ImageUploading>
                )}
                {/* </Stack> */}
              </Card>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2 }}
                variant="contained"
              >
                {params.id ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Body>
    </>
  );
}
