import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import apiRoutes from "../apiConfigs"

export const postCommentApi = createAsyncThunk(
    'auth/postComments',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(apiRoutes.publicapiAction(`get_comments`, 1), { params: data })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)
const intialState = { result: [], loading: false, error: null, message: "" }

const postCommentSlice = createSlice({
    name: 'postComment list',
    initialState: intialState,
    reducers: {
        resetPostComment: () => intialState,
    },
    extraReducers: (builder) => {
        builder.addCase(postCommentApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(postCommentApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result?.comments
            state.message = action?.payload?.message
            state.total = action?.payload?.comment_count
        })
        builder.addCase(postCommentApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const { resetPostComment } = postCommentSlice.actions
export const postCommentReducer = postCommentSlice.reducer