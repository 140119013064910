import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../../config/authAxios";
import apiRoutes from "../../apiConfigs";

export const EditReportReasonApi = createAsyncThunk(
  "auth/teacher-lounge/EditReportReason",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.patch(apiRoutes.publicapiAction(`report_reason`, 1),
        data
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const EditReportReasonSlice = createSlice({
  name: "Edit ReportReason",
  initialState: { result: [], loading: false, error: null, message: "" },
  extraReducers: (builder) => {
    builder.addCase(EditReportReasonApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(EditReportReasonApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(EditReportReasonApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});

export const EditReportReasonreducer = EditReportReasonSlice.reducer;
