import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const getEventModuleAPI = createAsyncThunk(
  "auth/event/getParticularEventModule",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.get(
        apiRoutes.publicapiAction(`events`, 1)
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
const intialState = { result: [], loading: false, error: null, message: "" };
const getDetailsEventModuleSlice = createSlice({
  name: "get EventModule",
  initialState: { result: [], loading: false, error: null, message: "" },
  reducers: {
    resetGetEventModule: () => intialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getEventModuleAPI.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getEventModuleAPI.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(getEventModuleAPI.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});
export const { resetGetEventModule } = getDetailsEventModuleSlice.actions;
export const getDetailsEventModuleReducer =
  getDetailsEventModuleSlice.reducer;
