import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Badge,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Chip,
  TextField,
  FormLabel,
  TextareaAutosize,
  FormControlLabel,
  Radio,
  RadioGroup,
  Paper
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React, { useState, useRef, useEffect, useContext, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import ImageUploading from "react-images-uploading";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import RichTextEditor from "react-rte";
import { postAddApi } from "../../store/teacher-lounge/addPost";
import { toast } from "react-hot-toast";
import { postCommentEditApi } from "../../store/teacher-lounge/editPostComment";
import { postCommentAddApi } from "../../store/teacher-lounge/addPostComment";
import { uploadImageApi } from "../../store/upload/uploadImage";
import { useTheme } from '@mui/material/styles';
import { postCommentApi, resetPostComment } from "../../store/teacher-lounge/postCommentList"
import PublishUnpublishModule from "../../components/PublishUnpublishModulePost";
import DataGridComponent from "../../components/DataGridComponent";
import { getGridStringOperators } from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import { ThemeContext } from '../../context/ThemeContext';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import NodataImg from "../../assets/images/noData.png";
import CustomGridToolbar from "../../components/CustomGridToolbar";
import apiRoutes from "../../store/apiConfigs";
import BackdropUI from "../../ui/Backdrop";
import DeleteReportReason from "./deleteCommentReply";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const schema = yup
  .object({
    content: yup.string().required("content cannot be empty").trim(),
  })
  .required();

export default function EditPostComment() {

  const historyLocation = useLocation();
  const { postCommentData, postId, postData } = historyLocation.state || {};
  const theme = useTheme();
  const postCommentEdit = useSelector((state) => state?.postCommentEdit);
  const postCommentAdd = useSelector((state) => state?.postCommentAdd);
  const podcastSelector = useSelector((state) => state?.postComments);
  const [deleteId, setDeleteId] = useState(null);
  const [notSelectedProductModel, setNotSelectedProductModel] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const { result, loading, total } = podcastSelector;
  const dispatch = useDispatch();
  const { paginationModel, setPaginationModel } = useContext(ThemeContext)
  const [queryOptions, setQueryOptions] = useState({});
  const [isDownload, setIsDownload] = useState(false)
  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setValue
  } = useForm({ resolver: yupResolver(schema), defaultValues: {} });
  const params = useParams();
  const navigate = useNavigate();


  const handleUpdateForm = async (data) => {
    var newObj = { ...data, post_id: postId };
    if (params.id) {
      delete newObj.post_id;
      newObj = { ...newObj, comment_id: params.id };
      await dispatch(postCommentEditApi(newObj));
      if (!postCommentEdit?.loading) {
        navigate(`/edit-post/${postId}`, { state: { postData } });
        //console.log("edit");
      }
    } else {
      await dispatch(postCommentAddApi(newObj));
      if (!postCommentAdd?.loading) {
        navigate(`/edit-post/${postId}`, { state: { postData } });

      }
    }
  };

  useEffect(() => {
    let defalutValues = {};
    defalutValues["content"] = postCommentData?.content;
    reset(defalutValues);
  }, [postCommentData]);

  const columns = [
    {
      field: "id",
      headerName: "Reply Id",
      filterable: false,
      sortable: false,
      width: 150,
    },
    {
      field: "content",
      headerName: "Comment",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      width: 350,
    },
    {
      field: "user_fullname",
      headerName: "Added By",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      width: 350,
    },
    {
      field: "update_post_comment",
      headerName: "Update comment",
      filterable: false,
      sortable: false,
      width: 150,
      disableExport: true,
      renderCell: (fieldData) => (
        <>
          <Button
            color="info"
            endIcon={<EditIcon />}
            label="Available" variant="outlined"
            onClick={() => navigate(
              `/edit-post-comment-reply/${fieldData.id}`, {
              state: { postCommentReplyData: fieldData.row, postCommentData, postId: params.id, postData }
            }
            )}

          >
            Edit
          </Button>
        </>
      ),
    },
    {
      field: "delete_post_comment_reply",
      headerName: "Delete reply",
      filterable: false,
      sortable: false,
      width: 150,
      disableExport: true,
      renderCell: (row) => (
        <>
          <Button
            endIcon={<DeleteOutlineIcon />}
            color="error"
            variant="outlined"
            style={{ marginLeft: "5px" }}
            onClick={() => {
              setNotSelectedProductModel(true);
              setDeleteId(row.id);
            }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Typography>Comment Details</Typography>
                <Stack spacing={2} my={2}>
                  <Divider />
                  <>
                    <InputLabel htmlFor="outlined-adornment-email" shrink>
                      Content
                    </InputLabel>
                    <OutlinedInput
                      {...register("content")}
                      label="content"
                      notched
                      multiline
                      id="outlined-adornment-content"
                      error={errors?.content?.message}
                      defaultValue={postCommentData?.content}
                    />
                  </>
                </Stack>
                <Button
                  type="submit"
                  sx={{ float: "right", mt: 2, ml: 2 }}
                  variant="contained"
                  onClick={() => navigate(`/edit-post/${postId}`, { state: { postData } })}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  sx={{ float: "right", mt: 2 }}
                  variant="contained"
                >
                  {params.id ? "Update" : "Create"}
                </Button>
              </Card>
              <br />
              <br />
              <Card>
                <Typography>Comment replys details</Typography>
                <Paper elevation={0}>
                  <DataGridComponent
                    loading={loading}
                    rowCount={total}
                    pageSizeOptions={[5, 10, 20, 50, 100]}
                    pageSize={paginationModel?.pageSize}
                    onPageSizeChange={(newPageSize) => {
                      setPaginationModel({
                        ...paginationModel,
                        pageSize: newPageSize,
                      })
                    }}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pagination
                    paginationMode="server"
                    sx={{
                      height: 500
                    }}
                    getRowId={(row) => row.id}
                    rows={postCommentData?.replies}
                    columns={columns}
                    disableColumnSelector
                    disableDensitySelector
                    filterMode="server"
                    onFilterModelChange={onFilterChange}
                    slots={{
                      noRowsOverlay: () => (
                        <Box className="noData">
                          <img src={NodataImg} alt="no data found" />
                        </Box>
                      ),
                      toolbar: (props) =>
                        (CustomGridToolbar({ ...props, moduleType: "postManagement", queryOptions: queryOptions, paginationModel: paginationModel, isDownload: isDownload, setIsDownload: setIsDownload, apiUrl: apiRoutes.podcastsList })),
                    }}
                    slotProps={{
                      loadingOverlay: {
                        variant: 'linear-progress',
                        noRowsVariant: 'linear-progress',
                      },
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                  />
                </Paper>

              </Card>
            </Grid>
          </Grid>
        </form>
      </Body>
      <DeleteReportReason
        open={notSelectedProductModel}
        setOpen={setNotSelectedProductModel}
        setSelectedRow={setSelectedRow}
        id={deleteId}
        postData={postData}
      />
    </>
  );
}