import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../../config/authAxios"
import apiRoutes from "../../apiConfigs"

export const flaggedPostApi = createAsyncThunk(
    'auth/flaggedPosts',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(apiRoutes.publicapiAction(`get_reported_content`, 1), { params: data })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)
const intialState = { result: [], loading: false, error: null, message: "" }

const flaggedPostSlice = createSlice({
    name: 'flaggedPost list',
    initialState: intialState,
    reducers: {
        resetflaggedPost: () => intialState,
    },
    extraReducers: (builder) => {
        builder.addCase(flaggedPostApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(flaggedPostApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
            state.total = action?.payload?.count
        })
        builder.addCase(flaggedPostApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const { resetflaggedPost } = flaggedPostSlice.actions
export const flaggedPostReducer = flaggedPostSlice.reducer