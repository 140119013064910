import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Badge,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Chip,
  TextField,
  FormLabel,
  TextareaAutosize,
  FormControlLabel,
  Radio,
  RadioGroup,
  Paper
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React, { useState, useRef, useEffect, useContext, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import ImageUploading from "react-images-uploading";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import RichTextEditor from "react-rte";
import { postAddApi } from "../../store/teacher-lounge/addPost";
import { toast } from "react-hot-toast";
import { postEditApi } from "../../store/teacher-lounge/editPost";
import { uploadImageApi, resetImage } from "../../store/upload/uploadImage";
import { useTheme } from '@mui/material/styles';
import { postCommentApi, resetPostComment } from "../../store/teacher-lounge/postCommentList"
import PublishUnpublishModule from "../../components/PublishUnpublishModulePost";
import DataGridComponent from "../../components/DataGridComponent";
import { getGridStringOperators } from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import { ThemeContext } from '../../context/ThemeContext';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import NodataImg from "../../assets/images/noData.png";
import CustomGridToolbar from "../../components/CustomGridToolbar";
import apiRoutes from "../../store/apiConfigs";
import BackdropUI from "../../ui/Backdrop";
import DeleteReportReason from "./deleteComment";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function getYouTubeVideoId(url) {
  const urlObj = new URL(url);

  if (urlObj.hostname === "youtu.be") {
    return urlObj.pathname.slice(1);
  } else if (urlObj.hostname === "www.youtube.com" || urlObj.hostname === "youtube.com") {
    if (urlObj.pathname.startsWith("/watch")) {
      return urlObj.searchParams.get("v");
    } else if (urlObj.pathname.startsWith("/embed/")) {
      return urlObj.pathname.split("/embed/")[1].split("?")[0];
    }
  }

  return null;
}

const schema = yup
  .object({
    youtube_link: yup.string().optional("Please Provide Video Link").nullable("").trim(),
    post_type: yup.string().required("Please Provide Post Type").trim(),
  })
  .required();

export default function EditPost() {

  const podcastEdit = useSelector((state) => state?.podcastEdit);
  const podcastAdd = useSelector((state) => state?.podcastAdd);
  const podcastDetails = useSelector((state) => state?.podcastDetails);
  const uploadImage = useSelector((state) => state?.uploadImage);
  const historyLocation = useLocation();
  const [deleteId, setDeleteId] = useState(null);
  const [notSelectedProductModel, setNotSelectedProductModel] = useState(false);
  const { postData } = historyLocation.state || {};
  const theme = useTheme();
  const {
    result: {
      title,
      youtube_link,
      mobile_number,
      duration: timeDuration,
      allocate_stars,
      meta_tags,
      category,
    },
  } = podcastDetails;

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setValue
  } = useForm({ resolver: yupResolver(schema), defaultValues: {} });
  const [images, setImages] = React.useState([]);
  const [imageLink, setImageLink] = useState();
  const [thumbnailLink, setThumbnail] = useState();
  const [isDownload, setIsDownload] = useState(false)
  const params = useParams();
  const [selectedRow, setSelectedRow] = useState([]);
  const [publishModel, setPublishModel] = useState(false);
  const navigate = useNavigate();
  const { paginationModel, setPaginationModel } = useContext(ThemeContext)
  const [queryOptions, setQueryOptions] = useState({});
  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);
  const podcastSelector = useSelector((state) => state?.postComments);
  const { result, loading, total } = podcastSelector;

  const onUploadImage = async (imageList, addUpdateIndex) => {
    if (imageList.length > 0) {
      let formData = new FormData();
      //console.log(imageList, imageList);
      formData.append("file", imageList[0]?.file);
      await dispatch(uploadImageApi(formData));
    }
    setImages(imageList);
  };

  const handleSelectQnsType = (evt, opt) => {
    setValue('post_type', evt?.target?.value)
    if (evt?.target?.value === "youtube") {
      setImages()
      setImageLink()
      setThumbnail()
    } else {
      setImages()
      setThumbnail()
      setImageLink()

    }
  }

  const handleUpdateForm = async (data) => {
    var newObj;
    if (data?.post_type === "image") {
      delete data.youtube_link;
      if (imageLink && Array.isArray(uploadImage.result) ? uploadImage.result.length === 0 : !uploadImage.result) {
        newObj = { ...data, image: imageLink, thumbnail: imageLink };
      } else if (
        images.length > 0 &&
        !uploadImage?.loading &&
        uploadImage.result
      ) {
        newObj = { ...data, image: uploadImage.result, thumbnail: uploadImage.result };
      } else {
        toast.error("Please Upload An Image", {
          id: "toast",
          duration: 4000,
          position: "top-center",
        });
        return;
      }
    } else {
      delete data.image;
      // if (imageLink && Array.isArray(uploadImage.result) ? uploadImage.result.length === 0 : !uploadImage.result) {
      //   newObj = { ...data, thumbnail: imageLink };
      // } else if (
      //   images.length > 0 &&
      //   !uploadImage?.loading &&
      //   uploadImage.result
      // ) {
      //   newObj = { ...data, thumbnail: uploadImage.result };
      // } else {
      //   toast.error("Please Upload An Image", {
      //     id: "toast",
      //     duration: 4000,
      //     position: "top-center",
      //   });
      //   return;
      // }
      newObj = { ...data, thumbnail: thumbnailLink };
    }
    newObj = { ...newObj };
    if (params.id) {
      newObj = { ...newObj, post_id: params.id };

      await dispatch(postEditApi(newObj)).then(() => {
        dispatch(resetImage())
      });
      if (!podcastEdit?.loading) {
        navigate(-1);
      }
    } else {
      await dispatch(postAddApi(newObj)).then(() => {
        dispatch(resetImage())
      });
      if (!podcastAdd?.loading) {
        navigate("/manage-post");

      }
    }
  };

  useEffect(() => {
    let defalutValues = {};
    defalutValues["youtube_link"] = postData?.youtube_link;
    defalutValues["post_type"] = postData?.post_type;
    defalutValues["description"] = postData?.description;
    if (postData?.post_type == "youtube") {
      setThumbnail(postData?.thumbnail)
      setImageLink(postData?.thumbnail)
    } else {
      setImageLink(postData?.image)
    }
    reset(defalutValues);
  }, [postData]);


  const getPostCommentList = useCallback((queryOptions, paginationModel) => {
    const filterModel = queryOptions?.filterModel;
    const items = filterModel?.items;
    const firstItem = items && items.length > 0 ? items[0] : {};
    const quickFilterValues = filterModel?.quickFilterValues;

    const query = {
      post_id: postData?.id,
      page: paginationModel?.page + 1,
      page_size: paginationModel?.pageSize,
      field: firstItem.field || '',
      operator: firstItem.operator || (quickFilterValues && "contains") || '',
      value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || ''
    };
    dispatch(postCommentApi(query));
  }, [dispatch])

  useEffect(() => {
    getPostCommentList(queryOptions, paginationModel)
    dispatch(resetPostComment());
  }, [dispatch, queryOptions, paginationModel, getPostCommentList]);

  const columns = [
    {
      field: "id",
      headerName: "Comment Id",
      filterable: false,
      sortable: false,
      width: 150,
    },
    {
      field: "content",
      headerName: "Comment",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      width: 350,
    },
    {
      field: "user_fullname",
      headerName: "Added By",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      width: 350,
    },
    {
      field: "update_post_comment",
      headerName: "Update comment",
      filterable: false,
      sortable: false,
      width: 150,
      disableExport: true,
      renderCell: (fieldData) => (
        <>
          <Button
            color="info"
            endIcon={<EditIcon />}
            label="Available" variant="outlined"
            onClick={() => navigate(
              `/edit-post-comment/${fieldData.id}`, {
              state: { postCommentData: fieldData.row, postId: params.id, postData, getPostCommentList: JSON.stringify(getPostCommentList) }
            }
            )}
          >
            Edit
          </Button>
        </>
      ),
    },
    {
      field: "delete_post_comment",
      headerName: "Delete comment",
      filterable: false,
      sortable: false,
      width: 150,
      disableExport: true,
      renderCell: (row) => (
        <>
          <Button
            endIcon={<DeleteOutlineIcon />}
            color="error"
            variant="outlined"
            style={{ marginLeft: "5px" }}
            onClick={() => {
              setNotSelectedProductModel(true);
              setDeleteId(row.id);
            }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    watch("youtube_link") && setThumbnail(`https://img.youtube.com/vi/${getYouTubeVideoId(watch("youtube_link"))}/hqdefault.jpg`)
  }, [watch("youtube_link")])
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Typography>Post Details</Typography>
                <Stack spacing={2} my={2}>
                  <Divider />
                  <>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <FormLabel>Post Type</FormLabel>
                        <RadioGroup defaultValue={postData?.post_type} value={watch("post_type")} display="flex" row>
                          <FormControlLabel onClick={handleSelectQnsType} value='youtube' control={<Radio />} label="Youtube" />
                          <FormControlLabel onClick={handleSelectQnsType} value='image' control={<Radio />} label="Image" />
                        </RadioGroup>
                        <FormHelperText error>
                          {errors?.post_type?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>

                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-email" shrink>
                        Description
                      </InputLabel>
                      <OutlinedInput
                        {...register("description")}
                        label="description"
                        notched
                        multiline
                        id="outlined-adornment-email"
                        error={errors?.description?.message}
                        defaultValue={postData?.description}
                      />
                    </FormControl>
                    {watch("post_type") === "youtube" && (
                      <>
                        <Stack direction="row" spacing={2}>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel shrink>Video Link</InputLabel>
                            <OutlinedInput
                              {...register("youtube_link")}
                              label="Video Link"
                              notched
                              id="outlined-adornment-email"
                              error={errors?.youtube_link?.message}
                              defaultValue={postData?.youtube_link}
                            />
                            <FormHelperText error>
                              {errors?.youtube_link?.message}
                            </FormHelperText>
                            <div className="image-item">
                              <img src={thumbnailLink} alt="" width="480" height="360" />
                            </div>
                          </FormControl>
                        </Stack>
                        {/* <Stack direction="row" spacing={2}>
                          <InputLabel style={{ marginTop: '2%' }}>Select Image </InputLabel>
                          {thumbnailLink ? (
                            <div className="image-item">
                              <img src={thumbnailLink} alt="" width="320" height="180" />
                              <div className="image-item__btn-wrapper">
                                <Button
                                  onClick={() => setThumbnail(null)}
                                  variant="contained"
                                >
                                  Remove
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <ImageUploading
                              // multiple
                              acceptType={['png', 'jpeg', 'jpg']}
                              value={images}
                              onChange={onUploadImage}
                              maxNumber={1}
                              // resolutionType="ratio"
                              dataURLKey="data_url"
                            // resolutionWidth={16}
                            // resolutionHeight={9}
                            >
                              {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                                errors,
                              }) => (
                                <>
                                  <div
                                    className="upload__image-wrapper"
                                    style={{ marginTop: "2%", marginBottom: "2%" }}
                                  >
                                    {imageList.length > 0 ? null : (
                                      <Button
                                        type="button"
                                        variant="contained"
                                        style={isDragging ? { color: "red" } : undefined}
                                        onClick={onImageUpload}
                                        {...dragProps}
                                      >
                                        Click or Drop here
                                      </Button>
                                    )}

                                    {errors && (
                                      <div>
                                        {errors.maxNumber && (
                                          <span>
                                            Number of selected images exceed maxNumber
                                          </span>
                                        )}
                                        {errors.acceptType && (
                                          <span>
                                            Your selected file type is not allow
                                          </span>
                                        )}
                                        {errors.maxFileSize && (
                                          <span>
                                            Selected file size exceed maxFileSize
                                          </span>
                                        )}
                                        {errors.resolution && (
                                          <span>
                                            Selected file is not match your desired
                                            resolution
                                          </span>
                                        )}
                                      </div>
                                    )}
                                    {imageList.map((image, index) => (
                                      <div key={index} className="image-item">
                                        <img
                                          src={image["data_url"]}
                                          alt=""
                                          width="320"
                                          height="180"
                                        />
                                        <div className="image-item__btn-wrapper">
                                          <Button
                                            onClick={() => onImageUpdate(index)}
                                            variant="contained"
                                            sx={{ mr: 2 }}
                                          >
                                            Update
                                          </Button>
                                          <Button
                                            onClick={() => onImageRemove(index)}
                                            variant="contained"
                                          >
                                            Remove
                                          </Button>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </>
                              )}
                            </ImageUploading>
                          )}
                        </Stack> */}
                      </>
                    )}
                    <Stack direction="row" spacing={2}>

                      {watch("post_type") === "image" && (
                        <>
                          <InputLabel style={{ marginTop: '2%' }}>Select Image </InputLabel>
                          {imageLink ? (
                            <div className="image-item">
                              <img src={imageLink} alt="" width="320" height="180" />
                              <div className="image-item__btn-wrapper">
                                <Button
                                  onClick={() => setImageLink(null)}
                                  variant="contained"
                                >
                                  Remove
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <ImageUploading
                              // multiple
                              acceptType={['png', 'jpeg', 'jpg']}
                              value={images}
                              onChange={onUploadImage}
                              maxNumber={1}
                              // resolutionType="ratio"
                              dataURLKey="data_url"
                            // resolutionWidth={16}
                            // resolutionHeight={9}
                            >
                              {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                                errors,
                              }) => (
                                <>
                                  <div
                                    className="upload__image-wrapper"
                                    style={{ marginTop: "2%", marginBottom: "2%" }}
                                  >
                                    {imageList.length > 0 ? null : (
                                      <Button
                                        type="button"
                                        variant="contained"
                                        style={isDragging ? { color: "red" } : undefined}
                                        onClick={onImageUpload}
                                        {...dragProps}
                                      >
                                        Click or Drop here
                                      </Button>
                                    )}

                                    {errors && (
                                      <div>
                                        {errors.maxNumber && (
                                          <span>
                                            Number of selected images exceed maxNumber
                                          </span>
                                        )}
                                        {errors.acceptType && (
                                          <span>
                                            Your selected file type is not allow
                                          </span>
                                        )}
                                        {errors.maxFileSize && (
                                          <span>
                                            Selected file size exceed maxFileSize
                                          </span>
                                        )}
                                        {errors.resolution && (
                                          <span>
                                            Selected file is not match your desired
                                            resolution
                                          </span>
                                        )}
                                      </div>
                                    )}
                                    {imageList.map((image, index) => (
                                      <div key={index} className="image-item">
                                        <img
                                          src={image["data_url"]}
                                          alt=""
                                          width="320"
                                          height="180"
                                        />
                                        <div className="image-item__btn-wrapper">
                                          <Button
                                            onClick={() => onImageUpdate(index)}
                                            variant="contained"
                                            sx={{ mr: 2 }}
                                          >
                                            Update
                                          </Button>
                                          <Button
                                            onClick={() => onImageRemove(index)}
                                            variant="contained"
                                          >
                                            Remove
                                          </Button>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </>
                              )}
                            </ImageUploading>
                          )}
                        </>
                      )}
                    </Stack>
                  </>
                </Stack>
                <Button
                  type="button"
                  sx={{ float: "right", mt: 2, ml: 2 }}
                  variant="contained"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  sx={{ float: "right", mt: 2 }}
                  variant="contained"
                >
                  {params.id ? "Update" : "Create"}
                </Button>
              </Card>
              {params.id && (<>
                <br />
                <br />
                <Card>
                  <Typography>Comment details</Typography>
                  <Paper elevation={0}>
                    <DataGridComponent
                      loading={loading}
                      rowCount={total}
                      pageSizeOptions={[5, 10, 20, 50, 100]}
                      pageSize={paginationModel?.pageSize}
                      onPageSizeChange={(newPageSize) => {
                        setPaginationModel({
                          ...paginationModel,
                          pageSize: newPageSize,
                        })
                      }}
                      paginationModel={paginationModel}
                      onPaginationModelChange={setPaginationModel}
                      pagination
                      paginationMode="server"
                      sx={{
                        height: 500
                      }}
                      getRowId={(row) => row.id}
                      rows={result}
                      columns={columns}
                      disableColumnSelector
                      disableDensitySelector
                      filterMode="server"
                      onFilterModelChange={onFilterChange}
                      slots={{
                        noRowsOverlay: () => (
                          <Box className="noData">
                            <img src={NodataImg} alt="no data found" />
                          </Box>
                        ),
                        toolbar: (props) =>
                          (CustomGridToolbar({ ...props, moduleType: "postManagement", queryOptions: queryOptions, paginationModel: paginationModel, isDownload: isDownload, setIsDownload: setIsDownload, apiUrl: apiRoutes.podcastsList })),
                      }}
                      slotProps={{
                        loadingOverlay: {
                          variant: 'linear-progress',
                          noRowsVariant: 'linear-progress',
                        },
                        toolbar: {
                          showQuickFilter: true,
                        },
                      }}
                    />
                  </Paper>

                </Card>
              </>)}
            </Grid>
          </Grid>
        </form>
      </Body>
      <BackdropUI open={isDownload} />
      <DeleteReportReason
        open={notSelectedProductModel}
        setOpen={setNotSelectedProductModel}
        setSelectedRow={setSelectedRow}
        id={deleteId}
        getPostCommentList={getPostCommentList}
        queryOptions={queryOptions}
        paginationModel={paginationModel}
      />
    </>
  );
}