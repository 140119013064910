import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const responseListingApi = createAsyncThunk(
  "auth/responseListing",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.get(
        apiRoutes.competitionAction('user-list/'),
        { params: data }
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
const initialState = { result: [], loading: false, error: null, message: "", total: 0 }

const responseListingSlice = createSlice({
  name: "responseListing",
  initialState,
  reducers: {
    resetresponseListing: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(responseListingApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(responseListingApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.results?.result??[];
      state.message = action?.payload?.message;
      state.total = action?.payload?.count
    });
    builder.addCase(responseListingApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});
export const { resetresponseListing } = responseListingSlice.actions;
export const responseListingreducer = responseListingSlice.reducer;
