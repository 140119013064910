import { yupResolver } from "@hookform/resolvers/yup";
import {
  Badge,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { addEventModuleApi } from "../../store/event-module/addevent";
import { EditEventModuleApi } from "../../store/event-module/editevent";
import { uploadImageApi } from "../../store/upload/uploadImage";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ImageUploading from "react-images-uploading";
import dayjs from "dayjs";
import { toast } from "react-hot-toast";
import BackdropUI from "../../ui/Backdrop";
import { SketchPicker } from "react-color";

const schema = yup
  .object({
    title: yup.string().required("Please add title").trim(),
    description: yup.string().required("Please add description").trim(),
    start_date: yup.string().required("Please add start date").trim(),
    end_date: yup.string().required("Please add end date").trim(),
  })
  .required();

export default function EventModuleEdit() {
  const settingDetails = useSelector((state) => state?.settingDetails);
  const [fileUploadLoader, setFileUploadLoader] = useState(false);
  const uploadImage = useSelector((state) => state?.uploadImage);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const params = useParams();
  const navigate = useNavigate();
  const classAdd = useSelector((state) => state?.addEventModule);
  const classEdit = useSelector((state) => state?.EditEventModule);
  const historyLocation = useLocation();
  const [colour, setColour] = useState();
  const [showColorPicker, setShowColorPicker] = useState();
  const [imageLink, setImageLink] = useState(null);
  const [images, setImages] = React.useState([]);

  const [error, setError] = useState(null);
  const [dateTime, setDateTime] = useState();
  const [endTime, setEnd] = useState();

  const { eventModuleData } = historyLocation.state || {};
  const handleUpdateForm = async (value) => {
    var newObj;
    newObj = { ...value };
    if (colour) {
      newObj = { ...newObj, color: colour };
    } else {
      toast.error("Please Provide Color", {
        id: "toast",
        duration: 1000,
        position: "top-center",
      });
      return;
    }
    if (imageLink) {
      newObj = { ...newObj, banner: imageLink };
    } else if (
      images.length > 0 &&
      !uploadImage?.loading &&
      uploadImage.result
    ) {
      newObj = { ...newObj, banner: uploadImage.result };
    } else {
      toast.error("Please Upload An Banner", {
        id: "toast",
        duration: 1000,
        position: "top-center",
      });
      return;
    }
    if (params.id) {
      newObj = { ...newObj, event_id: params.id };
      await dispatch(EditEventModuleApi(newObj));
      if (!classEdit?.loading) {
        navigate("/events");
      }
    } else {
      await dispatch(addEventModuleApi(newObj));
      if (!classAdd?.loading) {
        navigate("/events");
      }
    }
  };
  const onUploadImage = async (imageList, addUpdateIndex) => {
    try {
      let formData = new FormData();
      formData.append("file", imageList[0]?.file);
      if (imageList.length > 0) {
        setFileUploadLoader(true);
        dispatch(uploadImageApi(formData)).then((action) => {
          if (!action.type.includes("rejected")) {
            setImages(imageList);
          }
        }).finally(() => {
          setFileUploadLoader(false);
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
      setImages(imageList);
      // setFileUploadLoader(false)
    }

  };
  const handleChangeComplete = (color) => {
    //console.log(color.hex);
    setColour(color.hex);
  };
  useEffect(() => {
    let defalutValues = {};
    if (params.id) {
      defalutValues["title"] = eventModuleData?.title;
      defalutValues["description"] = eventModuleData?.description;
      defalutValues["start_date"] = eventModuleData?.start_date;
      defalutValues["end_date"] = eventModuleData?.end_date;
      setDateTime(eventModuleData?.start_date);
      setEnd(eventModuleData?.end_date);
      setImageLink(eventModuleData?.banner);
      setColour(eventModuleData?.color);

    }
    reset(defalutValues);
  }, [eventModuleData]);

  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Typography>Event Module Details</Typography>
                <Stack spacing={2} my={2}>
                  <Divider />

                  <Stack direction="row" spacing={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-category" shrink>
                        Title
                      </InputLabel>
                      <OutlinedInput
                        {...register("title")}
                        multiline
                        label="title"
                        notched
                        id="outlined-adornment-category"
                        error={errors?.title?.message}
                      />
                      <FormHelperText error>
                        {errors?.title?.message}
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-description" shrink>
                      Description
                    </InputLabel>
                    <OutlinedInput
                      {...register("description")}
                      label="description"
                      notched
                      multiline
                      id="outlined-adornment-description"
                      error={errors?.description?.message}
                      defaultValue={eventModuleData?.description}
                    />
                    <FormHelperText error>
                      {errors?.description?.message}
                    </FormHelperText>
                  </FormControl>
                  <Stack direction="row" spacing={2}>
                    <FormControl variant="outlined" fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          {...register("start_date")}
                          label="Start*"
                          name="start_date"
                          id="outlined-adornment-start_date"
                          onError={(newError) => setError(newError)}
                          maxDateTime={endTime ? dayjs(endTime) : null}
                          placeholder="Start"
                          error={errors?.start_date?.message}
                          value={dateTime ? dayjs(dateTime) : null}
                          onChange={(newValue) => {
                            const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
                            setDateTime(formattedDate);
                            setValue("start_date", formattedDate);
                          }}
                        />
                      </LocalizationProvider>
                      <FormHelperText error>
                        {errors?.start_date?.message}
                      </FormHelperText>
                    </FormControl>
                    <FormControl variant="outlined" fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          {...register("end_date")}
                          label="End*"
                          name="end_date"
                          id="outlined-adornment-email"
                          onError={(newError) => setError(newError)}
                          minDate={dateTime ? dayjs(dateTime) : null}
                          placeholder="End"
                          error={errors?.end_date?.message}
                          value={endTime ? dayjs(endTime) : null}
                          onChange={(newValue) => {
                            const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
                            setEnd(formattedDate);
                            setValue("end_date", formattedDate);
                          }}
                        />
                      </LocalizationProvider>
                      <FormHelperText error>
                        {errors?.end_date?.message}
                      </FormHelperText>
                    </FormControl>
                  </Stack>

                  <InputLabel>Thumbnail Image</InputLabel>
                  {imageLink ? (
                    <div className="image-item">
                      <img src={`${imageLink}?t=${Date.now()}`} alt="" width="320" height="180" />
                      <div className="image-item__btn-wrapper">
                        <Button
                          onClick={() => setImageLink(null)}
                          variant="contained"
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <ImageUploading
                      acceptType={['png', 'jpeg', 'jpg']}
                      value={images}
                      onChange={onUploadImage}
                      maxNumber={1}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                        errors,
                      }) => (
                        <>
                          <div
                            className="upload__image-wrapper"
                            style={{ marginTop: "2%", marginBottom: "2%" }}
                          >
                            {imageList.length > 0 ? null : (
                              <Button
                                type="button"
                                variant="contained"
                                style={isDragging ? { color: "red" } : undefined}
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                Click or Drop here
                              </Button>
                            )}
                            {errors && (
                              <div>
                                {errors.maxNumber && (
                                  <span>
                                    Number of selected images exceed maxNumber
                                  </span>
                                )}
                                {errors.acceptType && (
                                  <span>
                                    Your selected file type is not allow
                                  </span>
                                )}
                                {errors.maxFileSize && (
                                  <span>
                                    Selected file size exceed maxFileSize
                                  </span>
                                )}
                                {errors.resolution && (
                                  <span>
                                    Selected file is not match your desired
                                    resolution i.e (328*150)
                                  </span>
                                )}
                              </div>
                            )}
                            {imageList.map((image, index) => (
                              <div key={index} className="image-item">
                                <img
                                  src={image["data_url"]}
                                  alt=""
                                  width="320" height="180"
                                />
                                <div className="image-item__btn-wrapper">
                                  <Button
                                    onClick={() => onImageUpdate(index)}
                                    variant="contained"
                                    sx={{ mr: 2 }}
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    onClick={() => onImageRemove(index)}
                                    variant="contained"
                                  >
                                    Remove
                                  </Button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </ImageUploading>
                  )}
                </Stack>
                {/* <Stack direction="row" spacing={2}> */}
                <InputLabel>Background Color</InputLabel>

                {/* <Stack direction="row" spacing={2}> */}
                {showColorPicker ? (
                  <>
                    <SketchPicker
                      color={colour || ""}
                      onChange={(e) => handleChangeComplete(e)}
                    />
                  </>
                ) : (<div style={{ width: "100%" }}>
                  Colour is {colour}
                  <Button
                    variant="contained"

                    style={{ backgroundColor: colour, marginLeft: "2%" }}
                    onClick={() => setShowColorPicker(true)}
                  >
                    {colour ? "Change" : "Select"}
                  </Button>

                </div>
                )}
                {/* </Stack> */}
                {showColorPicker ? (
                  <Button
                    variant="contained"
                    onClick={() => setShowColorPicker(false)}

                  >
                    Set
                  </Button>
                ) : null}
                {/* </Stack> */}
              </Card>
              <Button
                type="button"
                sx={{ float: "right", mt: 2, ml: 2 }}
                variant="contained"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{ float: "right", mt: 2 }}
                variant="contained"
              >
                {params.id ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Body>
      <BackdropUI open={fileUploadLoader} />
    </>
  );
}
