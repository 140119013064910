import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState, useCallback, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import apiRoutes from "../../store/apiConfigs";
import DataGridComponent from "../../components/DataGridComponent";
import {
  moduleListingApi,
  resetModuleListing,
} from "../../store/channel/moduleListing";
import { assignObjectToChannelApi } from "../../store/channel/assignObjectToChannel";
import CustomGridToolbar from "../../components/CustomGridToolbarWithoutDownload";
import { getGridStringOperators } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { AuthContext } from "../../context/AuthContext";
import { responseListingApi } from "../../store/competition/userResponses";
import DeleteUserEntry from "./deleteUserEntry";
import { Link, useNavigate, useParams } from "react-router-dom";
import RankAssign from "./rankassign";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function UserEnteryModuleList({
  getRowSpacing,
  NoRowsOverlay,
  channelCategory,
  channel_id,
  callObjectList,
}) {
  const {
    result: moduleListingResult,
    loading: moduleListingLoading,
    total: moduleListingTotal,
  } = useSelector((state) => state?.responseListing);
  const [
    queryOptionsChangeAssignedModule,
    setQueryOptionsChangeAssignedModule,
  ] = useState({});
  const [queryOptionsChangeModuleListing, setQueryOptionsChangeModuleListing] =
    useState({});
  const [
    paginationModelChangeModuleListing,
    setPaginationModelChangeModuleListing,
  ] = useState({
    page: 0,
    pageSize: 20,
  });
  const dispatch = useDispatch();
  const auth = useContext(AuthContext);
  const [deleteId, setDeleteId] = useState(null);
  const [userId, setUserId] = useState(null);
  const paginationModelModuleListingMemoized = useMemo(
    () => paginationModelChangeModuleListing,
    [paginationModelChangeModuleListing]
  );
  const [isDownload, setIsDownload] = useState(false);
  const [notSelectedProductModel, setNotSelectedProductModel] = useState(false);
  const [assignRank, setAssignRank] = useState(false);
  const onFilterChangeModuleListing = useCallback((filterModel) => {
    setQueryOptionsChangeModuleListing({ filterModel: { ...filterModel } });
  }, []);
  const createQueryParams = useCallback(
    (paginationModel, queryOptions) => {
      const filterModel = queryOptions?.filterModel;
      const items = filterModel?.items;
      const firstItem = items && items.length > 0 ? items[0] : {};
      const quickFilterValues = filterModel?.quickFilterValues;

      return {
        page: paginationModel?.page + 1,
        page_size: paginationModel?.pageSize,
        field: firstItem.field || "",
        operator: firstItem.operator || (quickFilterValues && "contains") || "",
        search:
          firstItem.value ||
          (quickFilterValues &&
            quickFilterValues.toString().replaceAll(",", " ")) ||
          "",
        value:
          firstItem.value ||
          (quickFilterValues &&
            quickFilterValues.toString().replaceAll(",", " ")) ||
          "",
        
        reward_id: channel_id,
        type: channelCategory,
      };
    },
    [channel_id, channelCategory]
  );

  const callModulelistList = useCallback(() => {
    const queryModuleListing = createQueryParams(
      paginationModelChangeModuleListing,
      queryOptionsChangeModuleListing
    );
    dispatch(responseListingApi(queryModuleListing));
  }, [
    paginationModelChangeModuleListing,
    queryOptionsChangeModuleListing,
    createQueryParams,
    dispatch,
  ]);
const params = useParams();
  const handleAddtoChannel = async (record) => {
    dispatch(
      assignObjectToChannelApi({
        type: record.module_type
          ? record.module_type === "Course"
            ? "Courses"
            : record.module_type
          : record.module_type,
        module_id: record.id,
        channel_id,
      })
    )
      .then((action) => {
        if (!action.type.includes("rejected")) {
          callObjectList();
          callModulelistList();
        }
      })
      .catch((err) => console.log(err));
  };
  const handlePaginationModelChangeModuleListing = (model) => {
    if (
      paginationModelChangeModuleListing.page !== model.page ||
      paginationModelChangeModuleListing.pageSize !== model.pageSize
    ) {
      setPaginationModelChangeModuleListing(model);
    }
  };

  // Fetch assigned module data
  useEffect(() => {
    // dispatch(resetModuleListing())
    callModulelistList();
  }, [
    paginationModelModuleListingMemoized,
    queryOptionsChangeModuleListing,
    channelCategory,
    dispatch,
  ]);

  const moduleListingColumn = [
    {
      field: "user",
      headerName: "User Id",
      width: 250,
      filterable: true,
      sortable: false,
      hideable: false,
    },
    {
      field: "fullname",
      headerName: "Full Name",
      width: 450,
      filterable: false,
      filterOperators: getGridStringOperators().filter(
        (operator) =>
          (operator.value === "equals") | (operator.value === "contains")
      ),
      sortable: false,
      // renderCell: (data) => (
      //   <>
      //     <Typography sx={{ textWrap: "wrap" }}>{Array.isArray(data?.row?.object_class) && data?.row?.object_class.map((obj, index) => (`${obj['title']}, `))}</Typography>
      //   </>),
      hideable: false,
    },
    {
      field: "rank",
      headerName: "Rank",
      width: 250,
      filterable: false,
      sortable: false,
      hideable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      width: 400,
      disableExport: true,
      renderCell: (data) => (
        <>
          <Button
            color="info"
            endIcon={<AddIcon />}
            label="Available"
            variant="outlined"
            sx={{ margin: "0px 10px" }}
            onClick={() => {
              setAssignRank(true);
              setUserId(data.row.user);
            }
              
            }
            
          >
            Add
          </Button>
          <Button
            color="info"
            endIcon={<AddIcon />}
            label="Available"
            variant="outlined"
            sx={{ margin: "0px 10px", "text-decoration": 'none', }}
            // onClick={() => router.push(data.row.files.folder_link)}
          >
          <Link to={data.row.files.folder_link} target="_blank" rel="noopener noreferrer"
          style={{ "color": "#0288d1", "text-decoration": 'none', }}
          >
            Response
             </Link>
          </Button>
          <Button
            color="error"
            sx={{ margin: "0px 10px" }}
            label="Available"
            endIcon={<DeleteIcon />}
            variant="outlined"
            onClick={() => {
              setNotSelectedProductModel(true);
              setDeleteId(data.row.id);
            }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];
  return (
    <>
      <DataGridComponent
        loading={moduleListingLoading}
        rowCount={moduleListingTotal}
        pageSize={paginationModelChangeModuleListing?.pageSize}
        onPageSizeChange={(newPageSize) => {
          setPaginationModelChangeModuleListing({
            ...paginationModelChangeModuleListing,
            pageSize: newPageSize,
          });
        }}
        pagination
        paginationMode="server"
        paginationModel={paginationModelModuleListingMemoized}
        onPaginationModelChange={handlePaginationModelChangeModuleListing}
        sx={{
          height: 500,
        }}
        getRowId={(row) => `${row.id}-${row.module_type}-${uuidv4()}`}
        rows={moduleListingResult}
        getRowSpacing={getRowSpacing}
        columns={moduleListingColumn}
        onFilterModelChange={onFilterChangeModuleListing}
        slots={{
          noRowsOverlay: NoRowsOverlay,
          toolbar: (props) =>
            CustomGridToolbar({
              ...props,
              moduleType: "moduleListing",
              queryOptions: queryOptionsChangeModuleListing,
              paginationModel: paginationModelChangeModuleListing,
              isDownload: isDownload,
              setIsDownload: setIsDownload,
              apiUrl: apiRoutes.competitionAction(
                `user-list/reward_id=${channel_id}`
              ),
            }),
        }}
        slotProps={{
          loadingOverlay: {
            variant: "linear-progress",
            noRowsVariant: "linear-progress",
          },
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
      <DeleteUserEntry
        open={notSelectedProductModel}
        setOpen={setNotSelectedProductModel}
        id={deleteId}
        cid={params.id}
      />
       <RankAssign
        open={assignRank}
        setOpen={setAssignRank}
        id={userId}
        cid={params.id}
      />
    </>
  );
}
