import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Button,
  Card,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeContext";
// import { resultOutApi } from "../../store/competition/resultOut";
import { responseListingApi } from "../../store/competition/userResponses";
import { EditcompetitionApi } from "../../store/competition/editcompetition";
import { getParticularcompetitionAPI } from "../../store/competition/getcompetition";

export default function ResultOutModal({ open, setOpen, id, cid }) {
  const deleteSundayPoll = useSelector((state) => state?.hhwList);
  const { paginationModel } = useContext(ThemeContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = () => setOpen(false);

  const resultOutConfirm = async () => {
    var obj={
        id:cid,
        ended_by_admin:true,
    }
   await dispatch(EditcompetitionApi(obj));
    setOpen(false);
    if (!deleteSundayPoll?.loading) {
      dispatch(
        (getParticularcompetitionAPI(cid))
      );
      navigate(`/competition-responses/${cid}`);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Card className="modal" sx={{ width: "30%" }}>
        <Stack alignItems="flex-end">
          <IconButton onClick={handleClose}>
            <HighlightOffIcon />
          </IconButton>
        </Stack>
        <Stack alignItems="center" my={2}>
          <Typography variant="h5" fontWeight={600}>
            Are you sure you want to mark the result as out?
          </Typography>
          <Typography variant="h5" fontWeight={600}>
            Please click confirm
          </Typography>
        </Stack>
        <Stack sx={{ mt: 2 }} justifyContent="center" direction="row">
          <Button
            variant="outlined"
            onClick={() => setOpen(false)}
            sx={{ mr: 2 }}
          >
            Back
          </Button>
          <Button variant="contained" onClick={resultOutConfirm}>
            Confirm
          </Button>
        </Stack>
      </Card>
    </Modal>
  );
}

ResultOutModal.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  id: PropTypes.any,
  cid: PropTypes.any,
};