import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Badge,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Chip,
  TextField,
  FormLabel,
  TextareaAutosize,
  FormControlLabel,
  Radio,
  RadioGroup,
  Paper
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React, { useState, useRef, useEffect, useContext, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import ImageUploading from "react-images-uploading";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import RichTextEditor from "react-rte";
import { postAddApi } from "../../store/teacher-lounge/addPost";
import { toast } from "react-hot-toast";
import { postEditApi } from "../../store/teacher-lounge/editPost";
import { uploadImageApi } from "../../store/upload/uploadImage";
import { useTheme } from '@mui/material/styles';
import { postCommentApi, resetPostComment } from "../../store/teacher-lounge/postCommentList"
import PublishUnpublishModule from "../../components/PublishUnpublishModulePost";
import DataGridComponent from "../../components/DataGridComponent";
import { getGridStringOperators } from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import { ThemeContext } from '../../context/ThemeContext';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import NodataImg from "../../assets/images/noData.png";
import CustomGridToolbar from "../../components/CustomGridToolbar";
import apiRoutes from "../../store/apiConfigs";
import BackdropUI from "../../ui/Backdrop";
import { postCommentReplyEditApi } from "../../store/teacher-lounge/editCommentReply";
import { postCommentReplyAddApi } from "../../store/teacher-lounge/addCommentReply";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function getYouTubeVideoId(url) {
  const urlObj = new URL(url);

  if (urlObj.hostname === "youtu.be") {
    return urlObj.pathname.slice(1);
  } else if (urlObj.hostname === "www.youtube.com" || urlObj.hostname === "youtube.com") {
    if (urlObj.pathname.startsWith("/watch")) {
      return urlObj.searchParams.get("v");
    } else if (urlObj.pathname.startsWith("/embed/")) {
      return urlObj.pathname.split("/embed/")[1].split("?")[0];
    }
  }

  return null;
}

const schema = yup
  .object({
    content: yup.string().required("content cannot be empty").trim(),
  })
  .required();

export default function EditPostCommentReply() {

  const postCommentEdit = useSelector((state) => state?.postCommentReplyEdit);
  const postCommentAdd = useSelector((state) => state?.postCommentReplyAdd);
  const historyLocation = useLocation();
  const { postCommentReplyData, postCommentData, postId, postData } = historyLocation.state || {};
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setValue
  } = useForm({ resolver: yupResolver(schema), defaultValues: {} });
  const [images, setImages] = React.useState([]);
  const [imageLink, setImageLink] = useState();
  const [thumbnailLink, setThumbnail] = useState();
  const [isDownload, setIsDownload] = useState(false)
  const params = useParams();
  const [selectedRow, setSelectedRow] = useState([]);
  const [publishModel, setPublishModel] = useState(false);
  const navigate = useNavigate();
  const { paginationModel, setPaginationModel } = useContext(ThemeContext)
  const [queryOptions, setQueryOptions] = useState({});
  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);
  const podcastSelector = useSelector((state) => state?.postComments);
  const { result, loading, total } = podcastSelector;

  const onUploadImage = async (imageList, addUpdateIndex) => {
    if (imageList.length > 0) {
      let formData = new FormData();
      //console.log(imageList, imageList);
      formData.append("file", imageList[0]?.file);
      await dispatch(uploadImageApi(formData));
    }
    setImages(imageList);
  };

  const handleSelectQnsType = (evt, opt) => {
    setValue('post_type', evt?.target?.value)
    if (evt?.target?.value === "youtube") {
      setImages()
      setImageLink()
      setThumbnail()
    } else {
      setImages()
      setThumbnail()
      setImageLink()

    }
  }

  const handleUpdateForm = async (data) => {
    var newObj = { ...data, post_id: postId };
    if (params.id) {
      delete newObj.post_id;
      newObj = { ...newObj, reply_id: params.id };
      await dispatch(postCommentReplyEditApi(newObj));
      if (!postCommentEdit?.loading) {
        navigate(`/edit-post/${postId}`, { state: { postData, postCommentData } });
        //console.log("edit");
      }
    } else {
      await dispatch(postCommentReplyAddApi(newObj));
      if (!postCommentAdd?.loading) {
        navigate(`/edit-post/${postId}`, { state: { postData, postCommentData } });

      }
    }
  };

  useEffect(() => {
    let defalutValues = {};
    defalutValues["content"] = postCommentReplyData?.content;
    reset(defalutValues);
  }, [postCommentReplyData]);

  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <form onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Typography>Reply Details</Typography>
                <Stack spacing={2} my={2}>
                  <Divider />
                  <>
                    <InputLabel htmlFor="outlined-adornment-email" shrink>
                      Content
                    </InputLabel>
                    <OutlinedInput
                      {...register("content")}
                      label="content"
                      notched
                      multiline
                      id="outlined-adornment-content"
                      error={errors?.content?.message}
                      defaultValue={postCommentReplyData?.content}
                    />
                  </>
                </Stack>
                <Button
                  type="submit"
                  sx={{ float: "right", mt: 2, ml: 2 }}
                  variant="contained"
                  onClick={() => navigate(`/edit-post-comment/${postId}`, { state: { postData, postCommentData } })}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  sx={{ float: "right", mt: 2 }}
                  variant="contained"
                >
                  {params.id ? "Update" : "Create"}
                </Button>
              </Card>
            </Grid>
          </Grid>
        </form>
      </Body>
    </>
  );
}