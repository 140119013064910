import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Button,
  Card,
  IconButton,
  Modal,
  Stack,
  Typography,
  TextField,
  MenuItem,
} from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { ThemeContext } from "../../context/ThemeContext";
import { RankAssignApi } from "../../store/competition/rankAssign";
import { competitionApi } from "../../store/competition/competitionList";
import { responseListingApi } from "../../store/competition/userResponses";
import { winnerListingApi } from "../../store/competition/getwinner";

export default function RankAssign({ open, setOpen, id, cid }) {
  const { paginationModel } = useContext(ThemeContext);
  const [rank, setRank] = useState("");
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => setOpen(false);

  const handleRankChange = (event) => {
    setRank(event.target.value);
    setError(false);
  };

  const assignRankConfirm = async () => {
    if (!rank) {
      setError(true);
      return;
    }
    await dispatch(RankAssignApi({ user:id, rank:rank ,reward:Number(cid)}));
    setOpen(false);
    dispatch(
      winnerListingApi({
        page: paginationModel?.page + 1,
        page_size: paginationModel?.pageSize,
        id: cid,
      })
    );
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Card className="modal" sx={{ width: "30%" }}>
        <Stack alignItems="flex-end">
          <IconButton onClick={handleClose}>
            <HighlightOffIcon />
          </IconButton>
        </Stack>
        <Stack alignItems="center" my={2}>
          <Typography variant="h5" fontWeight={600}>
            Assign Rank
          </Typography>
          <TextField
            select
            label="Rank"
            value={rank}
            onChange={handleRankChange}
            sx={{ mt: 2, width: "50%" }}
            error={error}
            helperText={error ? "Rank is required" : ""}
          >
            {[...Array(10).keys()].map((value) => (
              <MenuItem key={value + 1} value={value + 1}>
                {value + 1}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        <Stack sx={{ mt: 2 }} justifyContent="center" direction="row">
          <Button
            variant="outlined"
            onClick={() => setOpen(false)}
            sx={{ mr: 2 }}
          >
            Back
          </Button>
          <Button variant="contained" onClick={assignRankConfirm}>
            Confirm
          </Button>
        </Stack>
      </Card>
    </Modal>
  );
}

RankAssign.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  id: PropTypes.any,
  cid: PropTypes.any,
};