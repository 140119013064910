import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const EditEventModuleApi = createAsyncThunk(
  "auth/event/EditEventModule",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.patch(apiRoutes.publicapiAction(`events`, 1),
        data
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const EditEventModuleSlice = createSlice({
  name: "Edit EventModule",
  initialState: { result: [], loading: false, error: null, message: "" },
  extraReducers: (builder) => {
    builder.addCase(EditEventModuleApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(EditEventModuleApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(EditEventModuleApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});

export const EditEventModulereducer = EditEventModuleSlice.reducer;
