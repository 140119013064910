import { Box, Button, Paper, Stack, Typography, Grid, FormControl, Card, FormHelperText } from "@mui/material";
import { getGridStringOperators } from "@mui/x-data-grid";
import { useEffect, useState, useCallback, useContext } from "react";
import { HiTemplate } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NodataImg from "../../assets/images/noData.png";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { flaggedPostApi, resetflaggedPost } from "../../store/teacher-lounge/flagged-post/flaggedPostList"
import { useParams } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import { ThemeContext } from '../../context/ThemeContext';
import CustomGridToolbar from "../../components/CustomGridToolbar";
import apiRoutes from "../../store/apiConfigs";
import BackdropUI from "../../ui/Backdrop";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import PublishUnpublishModule from "../../components/PublishUnpublishModulePost";
import DataGridComponent from "../../components/DataGridComponent";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

function getYouTubeVideoId(url) {
  const urlObj = new URL(url); // Parse the URL

  if (urlObj.hostname === "youtu.be") {
    return urlObj.pathname.slice(1);
  } else if (urlObj.hostname === "www.youtube.com" || urlObj.hostname === "youtube.com") {
    return urlObj.searchParams.get("v");
  }

  return null;
}

export default function ManagePost() {
  const podcastSelector = useSelector((state) => state?.flaggedPost);
  const { result, loading, total } = podcastSelector;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isDownload, setIsDownload] = useState(false)
  const [selectedRow, setSelectedRow] = useState([]);
  const [publishModel, setPublishModel] = useState(false);
  const { paginationModel, setPaginationModel } = useContext(ThemeContext)
  const [queryOptions, setQueryOptions] = useState({});
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const schema = yup
    .object({})
    .required();
  const schemaEdit = yup
    .object({})
    .required();
  const params = useParams();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(params.id ? schemaEdit : schema),
    defaultValues: {},
  });
  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);
  const columns = [
    {
      field: "flagged_post_id",
      headerName: "Post Id",
      filterable: false,
      sortable: false,
      width: 150,
      renderCell: (dataset) => (
        <Box sx={{ py: 1 }}>
          <Typography>{dataset?.row?.content_object?.id}</Typography>
        </Box>)
    },
    {
      field: "reported_by_user_fullname",
      headerName: "Added By",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      width: 350

    },
    {
      field: "reported_reason",
      headerName: "Report Reason",
      filterable: false,
      sortable: false,
      width: 350,
      renderCell: (dataset) => (
        <Box sx={{ py: 1 }}>
          <Typography>{dataset?.row?.report_reason?.reason}</Typography>
        </Box>)

    },
    {
      field: "post_type",
      headerName: "Post Type",
      filterable: false,
      sortable: false,
      width: 100,
      renderCell: (dataset) => (
        <Box sx={{ py: 1 }}>
          <Typography>{dataset?.row?.content_object?.post_type}</Typography>
        </Box>)
    },
    {
      field: "youtube_link",
      headerName: "Youtube Link",
      filterable: false,
      sortable: false,
      width: 400,
      renderCell: (dataset) => (
        <Box sx={{ py: 1 }}>
          <Typography>{dataset?.row?.content_object?.youtube_link}</Typography>
        </Box>)
    },
    {
      field: "image",
      headerName: "Thumbnail Image",
      filterable: false,
      sortable: false,
      width: 250,
      renderCell: (params) => (
        <Box sx={{ py: 1 }}>
          <img width={200} height={100} src={`${params.row.content_object.post_type === "youtube" ? params.row.content_object.thumbnail : params.row.content_object.image}`} alt='thumbnail' />
        </Box>)
    },
    {
      field: "update_post",
      headerName: "Update post",
      filterable: false,
      sortable: false,
      width: 150,
      disableExport: true,
      renderCell: (fieldData) => (
        <>
          <Button
            color="info"
            endIcon={<EditIcon />}
            label="Available" variant="outlined"
            onClick={() => navigate(
              `/edit-post/${fieldData.row.content_object.id}`, {
              state: { postData: fieldData.row.content_object }
            }
            )}

          >
            Edit
          </Button>
        </>
      ),
    },
    {
      field: "publish",
      headerName: "Publish Post",
      filterable: false,
      sortable: false,
      width: 200,
      disableExport: true,
      renderCell: (row) => (
        <>
          <Button
            color="info"
            label="Available" variant="outlined"
            onClick={() => {
              setPublishModel(true)
              setSelectedRow(row.row)
            }}
            endIcon={<ArrowRightAltIcon />}
          >
            {row.row?.content_object.post_approval ? "Unpublish" : "Publish"}
          </Button>
        </>
      ),
    },
  ];

  const getPostList = useCallback((queryOptions, paginationModel) => {
    const filterModel = queryOptions?.filterModel;
    const items = filterModel?.items;
    const firstItem = items && items.length > 0 ? items[0] : {};
    const quickFilterValues = filterModel?.quickFilterValues;

    const query = {
      page: paginationModel?.page + 1,
      page_size: paginationModel?.pageSize,
      field: firstItem.field || '',
      operator: firstItem.operator || (quickFilterValues && "contains") || '',
      value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || '',
      start_date: startDate,
      end_date: endDate
    };
    dispatch(flaggedPostApi(query));
  }, [dispatch, startDate, endDate])

  useEffect(() => {
    getPostList(queryOptions, paginationModel)
    dispatch(resetflaggedPost());
  }, [dispatch, queryOptions, paginationModel, getPostList, startDate, endDate]);

  const handleClear = async () => {
    setStartDate()
    setEndDate()
  };
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>

        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <HiTemplate size={30} />
          <Typography>Flagged Post List</Typography>
        </Stack>

        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <form id="outer_form_contect_manage_post">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Card>
                  <Stack direction="row" spacing={2}>
                    <FormControl variant="outlined" fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          {...register("startTime")}
                          label="From*"
                          name="startTime"
                          id="outlined-adornment-email"
                          onError={(newError) => setError(newError)}
                          maxDateTime={endDate ? dayjs(endDate) : null}
                          placeholder="From"
                          error={errors?.startTime?.message}
                          value={startDate ? dayjs(startDate) : null}
                          onChange={(newValue) => {
                            setStartDate(dayjs(newValue).format("YYYY-MM-DD"));
                          }}
                        />
                      </LocalizationProvider>
                      <FormHelperText error>
                        {errors?.startTime?.message}
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Card>
                  <Stack direction="row" spacing={2}>
                    <FormControl variant="outlined" fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          {...register("endDate")}
                          label="To*"
                          name="endDate"
                          id="outlined-adornment-email"
                          minDate={startDate ? dayjs(startDate) : null}
                          onError={(newError) => setError(newError)}
                          maxDateTime={endDate ? dayjs(endDate) : null}
                          disableFuture={true}
                          placeholder="To"
                          error={errors?.startTime?.message}
                          value={endDate ? dayjs(endDate) : null}
                          onChange={(newValue) => {
                            setEndDate(dayjs(newValue).format("YYYY-MM-DD"));
                          }}
                        />
                      </LocalizationProvider>
                      <FormHelperText error>
                        {errors?.startTime?.message}
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Card>
                  <Stack direction="row" spacing={2}>
                    <FormControl variant="outlined" fullWidth>
                      <Button sx={{
                        minHeight: "41px"
                      }} type="button" onClick={() => handleClear()} variant="outlined">Clear</Button>
                    </FormControl>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </form>
        </Stack>
        <Paper elevation={0}>
          <DataGridComponent
            loading={loading}
            rowCount={total}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            pageSize={paginationModel?.pageSize}
            onPageSizeChange={(newPageSize) => {
              setPaginationModel({
                ...paginationModel,
                pageSize: newPageSize,
              })
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pagination
            paginationMode="server"
            sx={{
              height: 500
            }}
            getRowId={(row) => row.id}
            rows={result}
            columns={columns}
            disableColumnSelector
            disableDensitySelector
            filterMode="server"
            onFilterModelChange={onFilterChange}
            slots={{
              noRowsOverlay: () => (
                <Box className="noData">
                  <img src={NodataImg} alt="no data found" />
                </Box>
              ),
              toolbar: (props) =>
                (CustomGridToolbar({ ...props, moduleType: "postManagement", queryOptions: queryOptions, paginationModel: paginationModel, isDownload: isDownload, setIsDownload: setIsDownload, apiUrl: apiRoutes.podcastsList })),
            }}
            slotProps={{
              loadingOverlay: {
                variant: 'linear-progress',
                noRowsVariant: 'linear-progress',
              },
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </Paper>
      </Body>

      {/* <DeletePodcast
        open={notSelectedProductModel}
        setOpen={setNotSelectedProductModel}
        id={deleteId}
      /> */}
      {/* <BulkPodcast
        open={podcastBulk}
        setOpen={setPodcastBulk}

      /> */}
      <PublishUnpublishModule
        open={publishModel}
        setOpen={setPublishModel}
        selectedRow={selectedRow}
        queryOptions={queryOptions}
        paginationModel={paginationModel}
        moduleType={"flaggedPost"}
        moduleList={getPostList}
      />
      <BackdropUI open={isDownload} />
    </>
  );
}
