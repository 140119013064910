import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAxios from "../../config/authAxios";
import apiRoutes from "../apiConfigs";

export const commentReplyDeleteApi = createAsyncThunk(
  "auth/commentReply/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthAxios.delete(apiRoutes.publicapiAction(`deleted_comment_reply`, 1), { data });
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const commentReplyDeleteSlice = createSlice({
  name: "commentReply delete",
  initialState: { result: [], loading: false, error: null, message: "" },
  extraReducers: (builder) => {
    builder.addCase(commentReplyDeleteApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(commentReplyDeleteApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action?.payload?.result;
      state.message = action?.payload?.message;
    });
    builder.addCase(commentReplyDeleteApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action?.payload?.message;
    });
  },
});

export const commentReplyDeleteReducer = commentReplyDeleteSlice.reducer;
