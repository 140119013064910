import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Button,
  Card,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeContext";
import { responseListingApi } from "../../store/competition/userResponses";
import { winnerListingApi } from "../../store/competition/getwinner";
import { competitionUserRankDeleteApi } from "../../store/competition/deleteuserRank";

export default function DeleteRank({ open, setOpen, id, cid,user }) {
  const deleteSundayPoll = useSelector((state) => state?.hhwList);
  const { paginationModel } = useContext(ThemeContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = () => setOpen(false);

  const deleteRankConfirm = async () => {
    await dispatch(competitionUserRankDeleteApi({ user:id, rank:0 ,reward:Number(cid)}));
    setOpen(false);
    if (!deleteSundayPoll?.loading) {
      dispatch(
        winnerListingApi({
          page: paginationModel?.page + 1,
          page_size: paginationModel?.pageSize,
          id: cid,
        })
        
      );
       dispatch(responseListingApi({
        page: paginationModel?.page + 1,
        page_size: paginationModel?.pageSize,
        reward_id: cid,
      }));
      navigate(`/competition-responses/${cid}`);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Card className="modal" sx={{ width: "30%" }}>
        <Stack alignItems="flex-end">
          <IconButton onClick={handleClose}>
            <HighlightOffIcon />
          </IconButton>
        </Stack>
        <Stack alignItems="center" my={2}>
          <Typography variant="h5" fontWeight={600}>
            Are you sure you want to remove this rank?
          </Typography>
          <Typography variant="h5" fontWeight={600}>
            Please click confirm
          </Typography>
        </Stack>
        <Stack sx={{ mt: 2 }} justifyContent="center" direction="row">
          <Button
            variant="outlined"
            onClick={() => setOpen(false)}
            sx={{ mr: 2 }}
          >
            Back
          </Button>
          <Button variant="contained" onClick={deleteRankConfirm}>
            Confirm
          </Button>
        </Stack>
      </Card>
    </Modal>
  );
}

DeleteRank.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  id: PropTypes.any,
  cid: PropTypes.any,
};